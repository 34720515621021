import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cloneDeep, sortBy } from 'lodash';
import AlertModal from 'components/common-components/AlertModal';
import Details from './Details';
import FolderPermission from './FolderPermission';
import Form from './Form';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import TabDividerVertical from 'components/common-components/TabDividerVertical';
import VersionHistory from './VersionHistory';
import { CallAPI } from 'actions/General';
import { formElementsDefaultProperties } from 'pages/form/utils';
import { FormSetupSchema } from '../utils';

const Setup = ({ formDetails, companySettings, updateTab, getFormDetail }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [folders, setFolders] = useState([]);
  const [values, setValues] = useState({
    formId: '',
    title: '',
    formIcon: '',
    textDirection: '',
    description: '',
    submitButtonColor: '',
  });
  const [displayValues, setDisplayValues] = useState({
    version: '',
    status: '',
    lastUpdated: null,
    lastUpdatedBy: '',
  });

  useEffect(() => {
    setFolders(
      formDetails && formDetails.appUser
        ? formDetails.appUser.folders.map((folder) => ({
            id: folder._id,
            name: folder.name,
          }))
        : [],
    );

    let sortedVersion = sortBy(formDetails?.versions ?? [], ['updatedAt']).reverse();
    let formCurrentVersion = sortedVersion.length > 0 ? sortedVersion[0].version : 1;
    setDisplayValues({
      version: formCurrentVersion,
      status: formDetails?.currentVersion?.formState,
      lastUpdated: formDetails?.updatedAt,
      lastUpdatedBy: formDetails?.lastUpdatedBy
        ? formDetails.lastUpdatedBy.firstName + ' ' + formDetails.lastUpdatedBy.lastName
        : '',
    });
  }, [formDetails]);

  useEffect(() => {
    setValues({
      formId: formDetails?._id ? formDetails._id : '',
      title: formDetails?.title ? formDetails.title : '',
      formIcon: formDetails?.formIcon ? formDetails.formIcon : 'attendance',
      textDirection:
        formDetails?.textDirection ?? companySettings?.textDirection ?? 'left_to_right',
      description: formDetails?.description ? formDetails.description : '',
      submitButtonColor: formDetails?.submitButtonColor ?? '#38CB89FF',
    });
  }, [formDetails, companySettings]);

  const [loading, setLoading] = useState(false);
  const [APIError, setAPIError] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [alertType, setAlertType] = useState('');
  const [formId, setFormId] = useState('');

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, [field]: value });
  };

  const onCancel = () => {
    if (formDetails) updateTab(0);
    else navigate('/forms');
  };

  const submit = async () => {
    try {
      await FormSetupSchema.validate(values, { abortEarly: false });
      let pageElement = {
        elementType: 'page',
        position: 1,
        pageNumber: 1,
        sectionNumber: 0,
        properties: cloneDeep(formElementsDefaultProperties.page),
      };

      const dataToUpdate = { ...values };

      if (values.formId === '') {
        dataToUpdate['supervisorElements'] = [];
        dataToUpdate['elements'] = [
          { ...pageElement, properties: JSON.stringify(pageElement.properties) },
        ];

        dataToUpdate['appUser'] = {
          folders: folders.map((item) => item.id),
          users: [],
          groups: [],
        };
        dataToUpdate['supervisor'] = { users: [], groups: [], folders: [] };
        dataToUpdate['designer'] = { users: [], groups: [], folders: [] };

        dataToUpdate['layoutSettings'] = {
          textDirection: 'left_to_right',
          showLogo: true,
          showQRCode: false,
          pageSize: 'A4',
          thumbSize: 'small',
          photoResolution: 'standard',
          mediaSummary: false,
          showUnansweredQuestions: true,
          showSupervisorFields: true,
          enableFooter: false,
          footerText: '',
          sampleReportForType: 'real_data',
        };
      } else {
        let users =
          formDetails && formDetails.appUser && formDetails.appUser.users
            ? formDetails.appUser.users
            : [];
        let groups =
          formDetails && formDetails.appUser && formDetails?.appUser?.groups
            ? formDetails.appUser.groups
            : [];
        dataToUpdate['appUser'] = {
          folders: folders.map((item) => item.id),
          users: users.map((item) => item._id),
          groups: groups.map((item) => item._id),
        };
      }
      let result = await CallAPI('SAVE_FORM', dataToUpdate, null, setLoading, setAPIError, null);
      if (result.status) {
        setFormId(formDetails && formDetails._id ? formDetails._id : result.data.formId);
        setAlertType(formDetails && formDetails._id ? 'update' : 'create');
        getFormDetail(formDetails && formDetails._id ? formDetails._id : result.data.formId);
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };

  return (
    <div className='form-setup'>
      <div className='p-4 border-bottom-grey-10-h-1'>
        <p className='text-uppercase theme-font-jakartaSans-medium mb-0'>
          {t('text_form_basic_settings')}
        </p>
      </div>
      <div className='setup-details'>
        <div>
          <Form
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            APIError={APIError}
          />
          <FolderPermission folders={folders} setFolders={setFolders} />
        </div>
        <div>
          <TabDividerVertical variant='grey-1' />
        </div>
        <div>
          <Details displayValues={displayValues} />
          <VersionHistory
            versions={sortBy(formDetails?.versions ?? [], ['updatedAt']).reverse()}
            formId={formDetails && formDetails._id ? formDetails._id : ''}
          />
        </div>
      </div>
      <div className='d-flex justify-content-end gap-3 theme-background-white-1 w-100 pb-3 px-5'>
        <FormButton text='button_cancel' variant='white-1' onClick={onCancel} />
        <FormLoadingButton
          text='button_save'
          variant='green-1'
          onClick={submit}
          loading={loading}
        />
      </div>
      <AlertModal
        show={alertType !== ''}
        showCloseButton={true}
        closeModal={() => setAlertType('')}
        title='alert_success'
        message={alertType === 'create' ? 'alert_form_created' : 'alert_form_updated'}
        variant='success'
        actionButtons={[
          {
            text: t('button_ok'),
            variant: 'green-1',
            onClick: () => {
              setAlertType('');
              if (alertType === 'create') {
                updateTab(0);
                navigate('/forms/edit', {
                  relative: 'path',
                  state: { type: 'edit', formId: formId },
                });
              }
            },
          },
        ]}
      />
    </div>
  );
};
Setup.propTypes = {
  formDetails: PropTypes.object,
  companySettings: PropTypes.object,
  updateTab: PropTypes.func,
  getFormDetail: PropTypes.func.isRequired,
};
export default Setup;
