import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const FieldList = ({ fieldCategory, fieldList, setDraggedElement, itemsDraggable }) => {
  const { t } = useTranslation();
  return (
    <div className='d-flex flex-column gap-2'>
      {fieldList.map((field, index) => (
        <div
          key={index}
          className='form-field-selection-item'
          draggable={itemsDraggable ?? false}
          onDragStart={() => setDraggedElement({ fieldCategory, elementType: field.elementType })}
          onDragEnd={(e) => {
            if (e.dataTransfer.dropEffect === 'none') setDraggedElement(null);
          }}
        >
          <img src={field.icon} />
          <span className='theme-size-0_8 theme-font-jakartaSans-medium theme-text-black-1'>
            {t(field.displayName)}
          </span>
        </div>
      ))}
    </div>
  );
};

FieldList.propTypes = {
  fieldCategory: PropTypes.string.isRequired,
  fieldList: PropTypes.array.isRequired,
  setDraggedElement: PropTypes.func.isRequired,
  itemsDraggable: PropTypes.bool,
};

export default FieldList;
