import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BindingComponent from '../BindingComponent';
import ExternalApiFields from './ExternalApiFields';
import ToggleBox from 'components/toggler-components/ToggleBox';
import ToggleBoxBody from 'components/toggler-components/ToggleBoxBody';
import ToggleBoxHeader from 'components/toggler-components/ToggleBoxHeader';

function ExternalApiBasicProperties({ properties, handleChange, otherDetails }) {
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <ToggleBox classes='mt-3'>
        <ToggleBoxHeader
          show={toggler}
          toggleBoxOpen={() => setToggler(!toggler)}
          title='form_external_data_source_details'
        />
        <ToggleBoxBody show={toggler}>
          <ExternalApiFields
            properties={properties}
            handleChange={handleChange}
            errors={{}}
            touched={{}}
            type='field-external-api'
          />
        </ToggleBoxBody>
      </ToggleBox>
      <BindingComponent
        properties={properties}
        handleChange={handleChange}
        otherDetails={otherDetails}
      />
    </>
  );
}
ExternalApiBasicProperties.propTypes = {
  properties: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default ExternalApiBasicProperties;
