import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SingleCheckBoxField from './SingleCheckBoxField';
// import { cloneDeep } from 'lodash';
import { isRTL } from 'config/config';

const SelectBox = ({
  name,
  listValues,
  label,
  fieldProps,
  classes,
  menuClasses,
  icon,
  selectedValues,
  handleChange,
  isAutoClose,
  position,
  positionReversed,
  variant,
  showCheckBoxes,
  placeholder,
  handleSubValueChange,
  valueChanged,
  example,
  ellipseSelected,
}) => {
  const { t } = useTranslation();
  const baseClass = variant
    ? ` custom-form-button theme-button-${variant ?? 'white-1'} min-width`
    : '';
  const ellipseStyle = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };

  const positionDirection = position
    ? position
    : (isRTL() && !positionReversed) || (!isRTL() && positionReversed)
    ? 'end'
    : 'start';
  return (
    <Dropdown
      drop={name === 'pagination' ? 'up' : isRTL() ? 'down' : 'down'}
      autoClose={isAutoClose ? '' : 'outside'}
      className={`${classes} ${placeholder ? 'w-100 ' : ''} ${example ? 'd-flex row m-0' : ''}`}
      align={{ sm: positionDirection }}
      {...fieldProps}
    >
      <Dropdown.Toggle
        id='dropdown-autoclose-outside'
        className={`dropdownItem${baseClass}${
          icon ? ' d-flex gap-2 justify-content-between align-items-center' : ''
        } ${icon && !example && 'w-100'}  ${example && 'col-6'}`}
        variant='none'
      >
        {label && (
          <label style={{ color: 'inherit', ...(ellipseSelected ? ellipseStyle : {}) }}>
            {t(label)}
          </label>
        )}
        {icon && <img src={icon} className='icon' />}
      </Dropdown.Toggle>
      {example && (
        <span className='d-flex justify-content-center align-items-center text-white example-text col-6'>
          {example}
        </span>
      )}
      <Dropdown.Menu
        className={`${menuClasses} ${name === 'pagination' ? 'mb-2' : ''}`}
        style={{ willChange: 'transform', padding: 0 }}
      >
        {listValues.map((singleValue, index) => {
          return (
            <Dropdown.Item
              key={index}
              onClick={() =>
                singleValue.isNested || !handleChange ? null : valueChanged(singleValue.value)
              }
              className={`${singleValue.optionClass ? singleValue.optionClass : ''} ${
                singleValue.isSelected ? 'active' : ''
              }`}
            >
              <span className={'d-flex gap-2'}>
                {showCheckBoxes && (
                  <SingleCheckBoxField
                    name={singleValue.value}
                    value={
                      singleValue.isNested ||
                      (selectedValues && selectedValues.indexOf(singleValue.value) > -1)
                    }
                    disabled={singleValue.isNested}
                  />
                )}
                {singleValue.icon && <img src={singleValue.icon} className='theme-width-20' />}
                {t(singleValue.displayValue)}
              </span>
              {singleValue.isNested && (
                <div className={`${singleValue.subOptionsSelectClass}`}>
                  <span className={`arrow ${singleValue.subOptionsArrowClass}`}> {'>'} </span>
                  <SelectField
                    name={singleValue.value}
                    selectedValues={singleValue.selectedValues}
                    listValues={singleValue.subOptions}
                    fieldProps={{ show: true }}
                    classes={'submenu'}
                    showCheckBoxes={showCheckBoxes}
                    position={positionDirection}
                    positionReversed={positionReversed}
                    handleChange={
                      handleChange
                        ? singleValue.handleChange
                          ? singleValue.handleChange
                          : handleSubValueChange
                        : null
                    }
                  />
                </div>
              )}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
SelectBox.propTypes = {
  listValues: PropTypes.array.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  position: PropTypes.string,
  positionReversed: PropTypes.bool,
  classes: PropTypes.string,
  menuClasses: PropTypes.string,
  fieldProps: PropTypes.object,
  icon: PropTypes.string,
  variant: PropTypes.string,
  selectedValues: PropTypes.array,
  handleSubValueChange: PropTypes.func,
  valueChanged: PropTypes.func,
  handleChange: PropTypes.func,
  rowData: PropTypes.object,
  isAutoClose: PropTypes.bool,
  showCheckBoxes: PropTypes.bool,
  placeholder: PropTypes.string,
  example: PropTypes.string,
  ellipseSelected: PropTypes.bool,
};
const SelectField = ({
  listValues,
  label,
  name,
  fieldProps,
  classes,
  menuClasses,
  icon,
  selectedValues,
  handleChange,
  isMultiple,
  isAutoClose,
  position,
  positionReversed,
  rowData,
  variant,
  showCheckBoxes,
  placeholder,
  isHorizontal,
  example,
  shrink,
  filedClasses,
  ellipseSelected,
}) => {
  const { t } = useTranslation();
  const valueChanged = (val) => {
    let rowDataVal = rowData ? rowData : name;
    if (isMultiple) {
      let values = [...selectedValues];
      if (values.indexOf(val) > -1) {
        values = values.splice(values.indexOf(val), 1);
      } else {
        values.push(val);
      }
      handleChange(rowDataVal, values);
    } else {
      handleChange(rowDataVal, val);
    }
  };
  const handleSubValueChange = (key, val) => {
    handleChange(key, val);

    // let receivedListValues = cloneDeep(listValues);
    // receivedListValues.map((singleListVal) => {
    //   if (singleListVal.value === val) {
    //     let selectedOptions = singleListVal.selectedValues;
    //     if (selectedOptions.indexOf(key) > -1) {
    //       selectedOptions.splice(selectedOptions.indexOf(key), 1);
    //     } else {
    //       selectedOptions.push(key);
    //     }
    //   }
    // });
    // handleChange(cloneDeep(receivedListValues), name);
  };
  return (
    <>
      {placeholder ? (
        <div className={`${isHorizontal ? 'd-flex align-items-center' : ''} ${filedClasses ?? ''}`}>
          {placeholder && (
            <span
              className={`${isHorizontal ? 'min-width-176' : ''}${shrink ? ' theme-size-0_8' : ''}`}
            >
              {t(placeholder)}
            </span>
          )}
          <SelectBox
            name={name}
            listValues={listValues}
            label={label}
            fieldProps={fieldProps}
            classes={classes}
            menuClasses={menuClasses}
            icon={icon}
            selectedValues={selectedValues}
            handleChange={handleChange}
            isAutoClose={isAutoClose}
            position={position}
            positionReversed={positionReversed}
            variant={variant}
            showCheckBoxes={showCheckBoxes}
            placeholder={placeholder}
            handleSubValueChange={handleSubValueChange}
            valueChanged={valueChanged}
            example={example}
            ellipseSelected={ellipseSelected}
          />
        </div>
      ) : (
        <SelectBox
          name={name}
          listValues={listValues}
          label={label}
          fieldProps={fieldProps}
          classes={classes}
          menuClasses={menuClasses}
          icon={icon}
          selectedValues={selectedValues}
          handleChange={handleChange}
          isAutoClose={isAutoClose}
          position={position}
          positionReversed={positionReversed}
          variant={variant}
          showCheckBoxes={showCheckBoxes}
          placeholder={placeholder}
          handleSubValueChange={handleSubValueChange}
          valueChanged={valueChanged}
          example={example}
          ellipseSelected={ellipseSelected}
        />
      )}
    </>
  );
};

SelectField.propTypes = {
  listValues: PropTypes.array.isRequired,
  label: PropTypes.string,
  position: PropTypes.string,
  positionReversed: PropTypes.bool,
  classes: PropTypes.string,
  menuClasses: PropTypes.string,
  fieldProps: PropTypes.object,
  icon: PropTypes.string,
  variant: PropTypes.string,
  selectedValues: PropTypes.array,
  isMultiple: PropTypes.bool,
  handleChange: PropTypes.func,
  rowData: PropTypes.object,
  isAutoClose: PropTypes.bool,
  showCheckBoxes: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  example: PropTypes.string,
  shrink: PropTypes.bool,
  filedClasses: PropTypes.string,
  ellipseSelected: PropTypes.bool,
};

export default SelectField;
