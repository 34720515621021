import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import dataSourceIcon from 'assets/images/icons/data-source-green.svg';

export const addDataSourceSchema = yup.object({
  schemaName: yup.string().required('Datasource name is required'),
  dataSourceType: yup.string().required('Datasource type is required'),
  externalId: yup.string().required('External id is required'),
});

export const addDataSourceColumnSchema = yup.object({
  columnName: yup.string().required('column name is required'),
});

export const initColumns = [
  {
    key: uuidv4(),
    name: 'Label 1',
    displayName: 'Label 1',
    type: 'text',
    isSelected: true,
  },
  {
    key: uuidv4(),
    name: 'Label 2',
    displayName: 'Label 2',
    type: 'text',
    isSelected: true,
  },
];

export const tabList = [
  {
    id: 1,
    title: 'tabs_data_base',
    isSelected: true,
    breadcrumbValue: { icon: dataSourceIcon, items: ['sidebar_data_source', 'tabs_data_base'] },
  },
  {
    id: 2,
    title: 'tabs_connections',
    isSelected: false,
    breadcrumbValue: { icon: dataSourceIcon, items: ['sidebar_data_source', 'tabs_connections'] },
  },
  {
    id: 3,
    title: 'tabs_file_doc',
    isSelected: false,
    breadcrumbValue: { icon: dataSourceIcon, items: ['sidebar_data_source', 'tabs_file_doc'] },
  },
];

export const DataSourceTableFields = [
  {
    name: 'name',
    displayName: 'table_header_data_source_name',
    isSelected: true,
    isFilterAble: true,
    type: 'text',
    associatedKey: 'schemaName',
  },
  {
    name: 'type',
    displayName: 'table_header_data_source_type',
    isSelected: true,
    associatedKey: 'dataSourceType',
    isFilterAble: true,
    type: 'text',
  },
  {
    name: 'externalId',
    displayName: 'table_header_data_source_external_id',
    isSelected: true,
    associatedKey: 'externalId',
    isFilterAble: true,
    type: 'text',
  },
  {
    name: 'lastUpdate',
    displayName: 'table_header_data_source_last_update',
    isSelected: true,
    associatedKey: 'updatedAt',
    populateFunc: 'populateDate',
    isFilterAble: false,
    type: 'text',
  },
  {
    name: 'lastUpdatedBy',
    displayName: 'table_header_data_source_last_updated_by',
    isSelected: true,
    associatedKey: 'lastUpdatedBy',
    populateFunc: 'databaseUpdatedBy',
    isFilterAble: false,
    type: 'text',
  },
  {
    name: 'keyFilter',
    displayName: 'table_header_data_source_key_filter',
    isSelected: true,
    associatedKey: 'dataSourceSchema',
    populateFunc: 'populateKeyFilterName',
    isFilterAble: false,
    type: 'text',
  },
  {
    name: 'records',
    displayName: 'table_header_data_source_records',
    isSelected: true,
    associatedKey: 'totalRows',
    isFilterAble: false,
    type: 'text',
  },
];

export const typeOptions = [
  {
    value: 'text',
    displayValue: 'select_text',
  },
  {
    value: 'number',
    displayValue: 'select_number',
  },
  {
    value: 'dateTime',
    displayValue: 'select_date_time',
  },
  // {
  //   name: 'boolean',
  // },
  // {
  //   name: 'image',
  // },
];
