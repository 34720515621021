import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AuthReducer } from '../../redux/auth';
import { cloneDeep } from 'lodash';
import { updateSavedChanges } from 'config/config';
import AlertModal from 'components/common-components/AlertModal';
import SwitchUrlConfirmContent from 'components/SwitchUrlConfirmContent';

const AuthAction = AuthReducer.actions;

const TabHeader = ({
  tabList,
  selectedTab,
  setSelectedTab,
  disabledId,
  variant,
  fixedWidth,
  tabUserName,
}) => {
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showNavigateConfirmModal, setShowNavigateConfirmModal] = useState(false);
  const [toTab, setToTab] = useState(null);
  useEffect(() => {
    if (selectedTab.breadcrumbValue) {
      let breadCrumbs = cloneDeep(selectedTab.breadcrumbValue);
      let breadCrumbsItems = [];
      breadCrumbs.items.map((singleItem) => {
        if (singleItem === '%%%User Name%%%') breadCrumbsItems.push(tabUserName);
        else breadCrumbsItems.push(singleItem);
      });
      breadCrumbs.items = breadCrumbsItems;
      dispatch(AuthAction.updateBreadCrumb({ breadcrumbs: breadCrumbs }));
    }
  }, [selectedTab, tabUserName]);

  const changeTab = (tab) => {
    if (auth.hasUnsavedChanges && auth.unsavedChangedType) {
      setToTab(tab);
      setShowNavigateConfirmModal(true);
    } else {
      updateSavedChanges(false);
      setSelectedTab(tab);
    }
  };
  const closeModal = () => {
    setShowNavigateConfirmModal(false);
    setToTab(null);
  };
  return (
    <div className={`tab-layout-header ${variant ?? ''}`}>
      {toTab && showNavigateConfirmModal && (
        <AlertModal show={showNavigateConfirmModal} closeModal={closeModal} actionButtons={[]}>
          <SwitchUrlConfirmContent
            toUrl={null}
            toTab={toTab}
            closeModal={closeModal}
            changeTab={setSelectedTab}
          />
        </AlertModal>
      )}
      {tabList.map((tab, index) => (
        <div
          key={index}
          className={`tab-item${selectedTab.id === tab.id ? ' active' : ''} ${
            disabledId && disabledId.indexOf(tab.id) > -1 ? 'disabled' : ''
          }${fixedWidth && 'fixed'}`}
          onClick={() => {
            disabledId && disabledId.indexOf(tab.id) > -1 ? null : changeTab(tab);
          }}
        >
          <img src={selectedTab.id === tab.id ? tab.activeIcon : tab.icon} />
          {t(tab.title)}
        </div>
      ))}
    </div>
  );
};

TabHeader.propTypes = {
  tabList: PropTypes.array.isRequired,
  selectedTab: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  disabledId: PropTypes.array,
  variant: PropTypes.string,
  fixedWidth: PropTypes.bool,
  tabUserName: PropTypes.string,
};

export default TabHeader;
