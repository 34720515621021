import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import filterLinesIcon from 'assets/images/icons/filter-lines.svg';
import keyIcon from 'assets/images/icons/key.svg';
import submissionAttachmentIcon from 'assets/images/icons/submission-attachment.svg';
import TextField from 'components/form-components/TextField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import AddDataSourceEntry from 'pages/datasource/datasourceList/AddDataSourceEntry';

const TableHeader = ({
  filterEnabled,
  mediaEnabled,
  tableFields,
  onSelection,
  isActionsEnabled,
  isSelectionEnabled,
  selectionValue,
  debounceFunc,
  filteringData,
  setFilteringData,
  addRow,
  closeRow,
  editedRowData,
  type,
  selectedColumns,
  onColumnSelection,
}) => {
  const { t } = useTranslation();

  return (
    <thead>
      {filterEnabled && (
        <tr>
          {isSelectionEnabled && <th className='filter-field'></th>}
          {tableFields.map((singleHeaderField, index) => {
            return singleHeaderField.isSelected ? (
              <th key={index} className='filter-field'>
                {singleHeaderField.isFilterAble ? (
                  <TextField
                    value={
                      filteringData[singleHeaderField.name]
                        ? filteringData[singleHeaderField.name]
                        : ''
                    }
                    name={singleHeaderField.name}
                    debounceFunc={debounceFunc}
                    handleChange={setFilteringData}
                    placeholder=''
                    shrink
                    classes='filter-field'
                  />
                ) : (
                  ''
                )}
              </th>
            ) : null;
          })}
          {isActionsEnabled && <th className='filter-field'></th>}
        </tr>
      )}
      <tr>
        {isSelectionEnabled && (
          <th className='table-checkbox'>
            <SingleCheckBoxField
              name={'all'}
              value={selectionValue}
              handleChange={onSelection}
              text={<></>}
              classes=''
            />
          </th>
        )}
        {tableFields.map((singleHeaderField, index) => {
          return singleHeaderField.isSelected ? (
            <th key={index} className='nowrap'>
              <div
                className={`${
                  type === 'submissions' ? 'd-flex gap-3 justify-content-between' : ''
                }`}
              >
                <div
                  className={`d-flex gap-2 align-items-center ${
                    singleHeaderField.className ? singleHeaderField.className : ''
                  }`}
                >
                  {type === 'data_sources_entries' && (
                    <SingleCheckBoxField
                      name={'column'}
                      value={
                        !!selectedColumns?.find((c) => c.columnId === singleHeaderField.columnId)
                      }
                      handleChange={() => onColumnSelection(singleHeaderField)}
                      classes='w-auto'
                      shrink
                    />
                  )}
                  {t(singleHeaderField.displayName)}
                  {singleHeaderField && singleHeaderField.isMediaAttached && mediaEnabled ? (
                    <img className='ms-3' src={submissionAttachmentIcon} />
                  ) : null}
                  {singleHeaderField?.name === 'keyFilter' && <img src={keyIcon} />}
                  {type === 'data_sources_entries' && singleHeaderField.isKeyFilter && (
                    <img src={keyIcon} />
                  )}
                  {type === 'data_sources_entries' && singleHeaderField.isFilter && (
                    <img src={filterLinesIcon} />
                  )}
                </div>
                {type === 'submissions' && <div className='border'></div>}
              </div>
            </th>
          ) : null;
        })}
        {isActionsEnabled && <th></th>}
      </tr>
      {addRow && (
        <AddDataSourceEntry
          columnsList={tableFields}
          closeRow={closeRow ? closeRow : null}
          editedRowData={editedRowData}
        />
      )}
    </thead>
  );
};

TableHeader.propTypes = {
  tableFields: PropTypes.array.isRequired,
  onSelection: PropTypes.func,
  filterEnabled: PropTypes.bool.isRequired,
  mediaEnabled: PropTypes.bool,
  isSelectionEnabled: PropTypes.bool,
  isActionsEnabled: PropTypes.bool.isRequired,
  selectionValue: PropTypes.bool.isRequired,
  debounceFunc: PropTypes.func,
  filteringData: PropTypes.object,
  setFilteringData: PropTypes.func,
  addRow: PropTypes.bool,
  closeRow: PropTypes.func,
  editedRowData: PropTypes.object,
  type: PropTypes.string,
  selectedColumns: PropTypes.array,
  onColumnSelection: PropTypes.func,
};

export default TableHeader;
