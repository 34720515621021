import React, { useState } from 'react';
import PropTypes from 'prop-types';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import ToggleBox from 'components/toggler-components/ToggleBox';
import ToggleBoxHeader from 'components/toggler-components/ToggleBoxHeader';
import ToggleBoxBody from 'components/toggler-components/ToggleBoxBody';
import SelectField from 'components/form-components/SelectField';

function DataSourceSelection({ properties, dataSourcesList, handleChange, columnValueList }) {
  const [toggler, setToggler] = useState(false);
  const dataSourceValues = dataSourcesList.map(({ displayValue, value }) => ({
    displayValue,
    value,
  }));
  const dataSourceValuesMap = Object.fromEntries(
    dataSourceValues.map((v) => [v.value, v.displayValue]),
  );
  const columnValuesMap = Object.fromEntries(columnValueList.map((v) => [v.value, v.displayValue]));

  return (
    <ToggleBox classes='mt-3'>
      <ToggleBoxHeader
        show={toggler}
        toggleBoxOpen={() => setToggler(!toggler)}
        title='form_internal_data_source_details'
      />
      <ToggleBoxBody show={toggler}>
        <SelectField
          placeholder='form_data_source'
          label={
            properties.dataSource !== ''
              ? dataSourceValuesMap[properties.dataSource]
              : 'select_select'
          }
          listValues={dataSourceValues}
          icon={arrowDownIcon}
          handleChange={handleChange}
          selectedValues={[]}
          name={'dataSource'}
          isAutoClose={true}
          variant='white-1'
          classes='mb-1'
          menuClasses={'w-100'}
          shrink
        />

        <div className='d-flex gap-3 mb-1'>
          <div className='w-100'>
            <SelectField
              disabled={properties.dataSource === ''}
              placeholder='form_field_display_column'
              label={
                properties.displayColumn !== ''
                  ? columnValuesMap[properties.displayColumn]
                  : 'select_select'
              }
              listValues={columnValueList}
              icon={arrowDownIcon}
              handleChange={handleChange}
              selectedValues={[]}
              name={'displayColumn'}
              isAutoClose={true}
              variant='white-1'
              menuClasses={'w-100'}
              shrink
            />
          </div>
          <div className='w-100'>
            <SelectField
              disabled={properties.dataSource === ''}
              placeholder='form_field_value_column'
              label={
                properties.valueColumn !== ''
                  ? columnValuesMap[properties.valueColumn]
                  : 'select_select'
              }
              listValues={columnValueList}
              icon={arrowDownIcon}
              handleChange={handleChange}
              selectedValues={[]}
              name={'valueColumn'}
              isAutoClose={true}
              variant='white-1'
              menuClasses={'w-100'}
              shrink
            />
          </div>
        </div>
      </ToggleBoxBody>
    </ToggleBox>
  );
}
DataSourceSelection.propTypes = {
  properties: PropTypes.object.isRequired,
  columnValueList: PropTypes.array.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default DataSourceSelection;
