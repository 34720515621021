import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CallAPI } from 'actions/General';
import Error from 'components/Error';
import Loader from 'components/common-components/Loader';
import TableComponent from 'components/table-component/Table';
import {
  archivedFormsTableHeader,
  checkHasActionPermission,
  formsTableHeader,
  formsTabsList,
} from './utils';
import TabLayout from 'components/tab-components/TabLayout';
import TabBody from 'components/tab-components/TabBody';
import TabHeader from 'components/tab-components/TabHeader';
import FormsListHeader from './FormsListHeader';
import { cloneDeep } from 'lodash';
import { permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';

const FormList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [allForms, setAllForms] = useState([]);
  const [selectedTab, setSelectedTab] = useState(formsTabsList[0]);
  const [totalForms, setTotalForms] = useState(0);
  const [loader, setLoader] = useState(false);
  const [APIError, setAPIError] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchTextData, setSearchTextData] = useState('');
  const [filteringData, setFilteringData] = useState({
    filterFolderIds: [],
  });

  const getAllForms = async (page = 1, limit = 10) => {
    let result = await CallAPI(
      'FETCH_FORMS',
      {
        screenTab: selectedTab.value,
        page: page,
        limit: limit,
        searchText: searchTextData,
        ...filteringData,
      },
      null,
      setLoader,
      setAPIError,
      null,
    );
    if (result.status) {
      let formsList = [];
      result.data.forms.map((singleForm) => {
        formsList.push({
          ...singleForm,
          currentVersion: singleForm.currentVersion ? singleForm.currentVersion : {},
          formFilterId: filteringData.filterFolderIds,
          publishedDate:
            singleForm.publishedVersion && singleForm.publishedVersion.publishedDate
              ? singleForm.publishedVersion.publishedDate
              : '',
        });
      });

      setAllForms(formsList);
      if (result.data.totalCount) setTotalForms(result.data.totalCount);
    }
  };
  const editForm = (rowId) => {
    navigate('/forms/edit', {
      relative: 'path',
      state: { type: 'edit', formId: rowId },
    });
  };
  const navigateToForms = () => {
    navigate('/forms/new', {
      relative: 'path',
      state: { type: 'add' },
    });
  };

  useEffect(() => {
    setSelectedRows([]);

    if (isPermissionAvailable(permissionKeys.field_work_forms_read)) {
      getAllForms();
    }
  }, [selectedTab, filteringData, searchTextData]);

  const onRowsSelection = (name, value) => {
    let selectedIds = cloneDeep(selectedRows);
    if (name === 'all') {
      allForms.map((single_form) => {
        if (value && selectedIds.map((e) => e.id).indexOf(single_form._id) === -1)
          selectedIds.push({ id: single_form._id, title: single_form.title });
        if (!value) selectedIds = [];
        setSelectedRows(selectedIds);
      });
    } else {
      if (value) {
        let title = '';
        allForms.map((single_form) => {
          if (single_form._id === name) title = single_form.title;
        });
        selectedIds.push({ id: name, title: title });
      } else selectedIds.splice(selectedIds.map((e) => e.id).indexOf(name), 1);
      setSelectedRows(selectedIds);
    }
  };

  const updateFilteringData = (name, value) => {
    let filteringDataObj = cloneDeep(filteringData);
    if (name === 'all' || name === 'no_folder') {
      filteringDataObj.filterFolderType = value ? name : '';
    } else if (name === 'custom') {
      if (value !== '') {
        filteringDataObj.filterFolderIds = [value];
        filteringDataObj.filterFolderType = 'custom';
      } else {
        filteringDataObj.filterFolderType = '';
        filteringDataObj.filterFolderIds = [];
      }
    }
    if (filteringDataObj.filterFolderType === '') delete filteringDataObj.filterFolderType;
    setFilteringData(filteringDataObj);
  };
  const debouncedFunction = (name, value) => {
    setSearchTextData(value);
  };
  const searchTextChanged = (name, value) => {
    setSearchText(value);
  };

  const formNameClickCallBack = (row) => {
    if (checkHasActionPermission(row.designer, row.createdBy))
      navigate('/forms/edit', {
        relative: 'path',
        state: { type: 'edit', formId: row._id },
      });
  };
  return (
    <TabLayout>
      <TabHeader
        tabList={formsTabsList}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <TabBody>
        {loader && <Loader color='green-1' type='modal-spinner' />}
        {APIError ? <Error msg={APIError} /> : <></>}
        <FormsListHeader
          actionFunction={selectedTab.value === 'forms' ? navigateToForms : null}
          selectedTab={selectedTab.value}
          setSearchText={searchTextChanged}
          searchText={searchText}
          selectedRows={selectedRows}
          getAllForms={getAllForms}
          debounceFunc={debouncedFunction}
          filteringData={filteringData}
        />
        <TableComponent
          data={allForms}
          header={selectedTab.value === 'archived' ? archivedFormsTableHeader : formsTableHeader}
          props={{
            type: 'form_list',
            editForm,
            actionsCallBack: getAllForms,
            actionsType: selectedTab.value,
            updateFilteringData,
            formNameClickCallBack,
            translateValueFunc: (val) => t(val),
          }}
          totalRecords={totalForms}
          filterEnabled={false}
          loader={loader}
          isFieldSelectionEnabled={false}
          isPaginationEnabled={true}
          isActionsEnabled={true}
          isSelectionEnabled={true}
          selectionKey={'_id'}
          paginationFunction={getAllForms}
          onSelection={onRowsSelection}
          selectedRows={selectedRows}
        />
      </TabBody>
    </TabLayout>
  );
};

export default FormList;
