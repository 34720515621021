import React from 'react';
import PropTypes from 'prop-types';
import infoCircleGreenIcon from 'assets/images/icons/info-circle-green.svg';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const InfoTooltip = ({ classes, text }) => {
  const { t } = useTranslation();

  return (
    <div className={`d-flex align-items-center ${classes || ''}`}>
      <OverlayTrigger
        placement='top-start'
        overlay={
          <Tooltip id='tooltip'>
            <label className='text-start theme-text-white-1 theme-size-0_9'>{t(text)}</label>
          </Tooltip>
        }
        // trigger={trigger === 'click' ? 'click' : 'hover'} // Use manual trigger
        trigger={['hover', 'focus']} // Use manual trigger
        rootClose={true}
        delay={{ show: 50, hide: 250 }} // Delay for show and hide
        flip={true}
        popperConfig={{
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport', // Restrict within the viewport
              },
            },
          ],
        }}
      >
        <img className='tooltip-icon' src={infoCircleGreenIcon} />
      </OverlayTrigger>
    </div>
  );
};

InfoTooltip.propTypes = {
  classes: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default InfoTooltip;
