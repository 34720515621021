import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import chevronGreyIcon from 'assets/images/icons/chevron-grey.svg';
import chevronIcon from 'assets/images/icons/chevron.svg';
import copyGreyIcon from 'assets/images/icons/copy-grey.svg';
import copyIcon from 'assets/images/icons/copy.svg';
import trashGreyIcon from 'assets/images/icons/trash-grey.svg';
import trashIcon from 'assets/images/icons/trash.svg';
import ConfirmationModal from 'components/common-components/ConfirmationModal';

const SwappingComponent = ({
  elementType,
  type,
  props,
  changeFormElementOrder,
  isSelected,
  showUpArrow,
  showDownArrow,
}) => {
  const { t } = useTranslation();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  return (
    <Fragment>
      <div
        className={`form-element-swapping ${
          ['section', 'supervisor_section'].includes(type) ? 'section' : ''
        }`}
      >
        <img
          className='cursor-pointer'
          onClick={() => setDeleteConfirmationModal(true)}
          src={isSelected ? trashIcon : trashGreyIcon}
        />
        {elementType !== 'supervisor_status' && (
          <img
            className='cursor-pointer'
            onClick={() => changeFormElementOrder(type, { ...props }, 'duplicate')}
            src={isSelected ? copyIcon : copyGreyIcon}
          />
        )}
        <img
          className={`cursor-move${!showUpArrow ? ' d-none' : ''}`}
          // className={`cursor-move`}
          onClick={() => changeFormElementOrder(type, { ...props }, 'decrease')}
          src={isSelected ? chevronIcon : chevronGreyIcon}
        />
        <img
          className={`cursor-move rotate-180${!showDownArrow ? ' d-none' : ''}`}
          // className={`cursor-move rotate-180`}
          onClick={() => changeFormElementOrder(type, { ...props }, 'increase')}
          src={isSelected ? chevronIcon : chevronGreyIcon}
        />
      </div>

      <ConfirmationModal
        show={deleteConfirmationModal}
        title={t('alert_form_element_deletion_title')}
        message={
          type === 'page'
            ? t('alert_page_deletion_message')
            : type === 'section'
            ? t('alert_section_deletion_message')
            : t('alert_field_deletion_message')
        }
        actionList={[
          {
            color: 'black-1',
            text: t('button_cancel'),
            onClick: () => setDeleteConfirmationModal(false),
          },
          {
            color: 'red-1',
            text: t('button_delete'),
            onClick: () => {
              changeFormElementOrder(type, { ...props }, 'delete');
              setDeleteConfirmationModal(false);
            },
          },
        ]}
      />
    </Fragment>
  );
};

SwappingComponent.propTypes = {
  props: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired,
  changeFormElementOrder: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  showUpArrow: PropTypes.bool,
  showDownArrow: PropTypes.bool,
};

export default SwappingComponent;
