import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import verticalBoxGroupIcon from 'assets/images/icons/vertical-box-group.svg';
import sectionIcon from 'assets/images/icons/section.svg';
import sectionCollapseIcon from 'assets/images/icons/section-collapse.svg';
import SwappingComponent from './SwappingComponent';

const SectionComponent = ({
  section,
  direction,
  selectComponent,
  changeFormElementOrder,
  isSelected,
  elementIndex,
  checkSwapArrow,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`fields-section-new ${isSelected ? 'selected' : ''}`}>
      <div className='fields-section-header'>
        <div
          className='d-flex gap-2 align-items-center cursor-pointer'
          onClick={() => selectComponent(elementIndex, section.elementType)}
        >
          <img
            src={sectionCollapseIcon}
            className={`${isSelected ? 'rotate-90' : `rotate-${direction === 'rtl' ? '180' : ''}`}`}
          />
          <label
            className='cursor-pointer'
            style={{
              color: section.properties?.basicProperties?.sectionTitleColor,
              fontSize: section.properties?.basicProperties?.sectionTitleSize,
            }}
          >
            {section.properties?.basicProperties?.sectionTitle
              ? section.properties?.basicProperties?.sectionTitle
              : t('form_untitled_section')}
          </label>
        </div>
        <div
          className='flex-grow-1 text-center cursor-pointer'
          onClick={() => selectComponent(elementIndex, section.elementType)}
        >
          <label className='theme-text-white-1 cursor-pointer'>
            {isSelected ? 'Click to close section' : 'Click to open section'}
          </label>
        </div>
        <div className='d-flex gap-2'>
          <div className='element-icon'>
            <img src={sectionIcon} className='svg-white' />
          </div>
          <SwappingComponent
            elementType='section'
            elementIcon={verticalBoxGroupIcon}
            type='section'
            props={{ position: elementIndex }}
            changeFormElementOrder={changeFormElementOrder}
            {...checkSwapArrow(elementIndex, section.elementType, 'user-field')}
          />
        </div>
      </div>
    </div>
  );
};

SectionComponent.propTypes = {
  section: PropTypes.object.isRequired,
  direction: PropTypes.string.isRequired,
  selectComponent: PropTypes.func.isRequired,
  changeFormElementOrder: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  elementIndex: PropTypes.number.isRequired,
  checkSwapArrow: PropTypes.func.isRequired,
};

export default SectionComponent;
