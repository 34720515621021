import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import plusIcon from 'assets/images/icons/plus.svg';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import Loader from 'components/common-components/Loader';
import ResponseSetAddModal from './ResponseSetAddModal';
import TableComponent from 'components/table-component/Table';
import { CallAPI } from 'actions/General';
import { responseSetsTableHeader } from './utils';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';
import responseSetGreenIcon from 'assets/images/icons/response-set-green.svg';
import { AuthReducer } from 'redux/auth';
import { useDispatch } from 'react-redux';
const AuthAction = AuthReducer.actions;

const ResponseSets = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [APIError, setAPIError] = useState('');
  const [responseSetList, setResponseSetList] = useState([]);
  const breadcrumbValue = {
    icon: responseSetGreenIcon,
    items: ['sidebar_response_set', 'tabs_response_set'],
  };

  const dispatch = useDispatch();

  const getAllResponseSets = async () => {
    const result = await CallAPI('GET_ALL_RESPONSE_SETS', null, null, setLoader, setAPIError, null);
    if (result.status) {
      setResponseSetList(result.data);
    }
  };

  const editDeleteResponseSetClick = (id, action) => {
    if (action === 'edit') setEditId(id);
    else if (action === 'delete') {
      setDeleteId(id);
      setDeleteConfirmationModal(true);
    }
  };

  const deleteResponseSet = async () => {
    const result = await CallAPI(
      'DELETE_RESPONSE_SET',
      null,
      deleteId,
      setLoader,
      setAPIError,
      null,
    );
    if (result.status) {
      toast.success('Response Set deleted successfully');
      getAllResponseSets();
    }
    setDeleteId(null);
  };

  const onCancel = () => {
    setShowModal(false);
    setEditId(null);
  };

  useEffect(() => {
    getAllResponseSets();
  }, []);

  useEffect(() => {
    dispatch(AuthAction.updateBreadCrumb({ breadcrumbs: breadcrumbValue}));
  }, [dispatch]);

  return (
    <div className='theme-background-white-1 custom-shadow rounded-sm h-100'>
      <div className='d-flex justify-content-between align-items-center p-3'>
        <label className='theme-size-1_2'>{t('text_create_multiple_response')}</label>
        {isPermissionAvailable(permissionKeys.field_work_response_set_create) && (
          <FormButton
            text={'button_add_response_set'}
            variant='green-2'
            icon={plusIcon}
            onClick={() => setShowModal(true)}
          />
        )}
      </div>
      {APIError ? <Error msg={APIError} /> : <></>}
      {loader && <Loader color='green-1' type='modal-spinner' />}

      <TableComponent
        data={responseSetList}
        header={responseSetsTableHeader}
        props={{
          type: 'response_set_list',
          editDeleteResponseSetClick: editDeleteResponseSetClick,
        }}
        totalRecords={1}
        filterEnabled={false}
        loader={loader}
        isFieldSelectionEnabled={false}
        isPaginationEnabled={false}
        isActionsEnabled={false}
        isSelectionEnabled={false}
        selectionKey={'_id'}
      />

      {(showModal || editId) && (
        <ResponseSetAddModal
          show={showModal || !!editId}
          editId={editId}
          onCancel={onCancel}
          refreshList={getAllResponseSets}
        />
      )}

      <ConfirmationModal
        show={deleteConfirmationModal}
        title={t('alert_common_title')}
        actionList={[
          {
            color: 'black-1',
            text: t('button_cancel'),
            onClick: () => {
              setDeleteId(null);
              setDeleteConfirmationModal(false);
            },
          },
          {
            color: 'red-1',
            text: t('button_delete'),
            onClick: () => {
              deleteResponseSet();
              setDeleteConfirmationModal(false);
            },
          },
        ]}
      />
    </div>
  );
};

export default ResponseSets;
