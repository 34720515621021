import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import filterGreyIcon from 'assets/images/icons/filter-grey.svg';
import filterLinesGreyIcon from 'assets/images/icons/filter-lines-grey.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import removeIcon from 'assets/images/icons/trash-red.svg';
import searchIcon from 'assets/images/icons/search.svg';
import selectArrow from 'assets/images/select-arrow.png';
import sortingIcon from 'assets/images/icons/sorting-icon.svg';
import sortingGreyIcon from 'assets/images/icons/sorting-icon-grey.svg';
import DateField from 'components/form-components/DateField';
import FormButton from 'components/form-components/FormButton';
import SelectField from 'components/form-components/SelectField';
import SingleRadioBoxField from 'components/form-components/SingleRadioBoxField';
import TextField from 'components/form-components/TextField';
import {
  conditionOperators,
  getApprovalStatusVariant,
  multiChoiceOperators,
  numberOperators,
  singleChoiceOperators,
  stringOperators,
} from './utils';
import SubmissionActions from './Actions';
import Export from 'components/common-components/Export';
import { defaultSupervisorStatusOptionsList, supervisorStatusValues } from 'pages/form/utils';

const SubmissionFilters = ({
  selectedRows,
  actionsCallBack,
  allQuestions,
  versionsList,
  filterData,
  setFilterData,
  showAttachment,
  setShowAttachment,
  queryFilter,
  setQueryFilter,
  searchState,
  handleSearchStateChange,
}) => {
  const [showQueryFilter, setShowQueryFilter] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const queryInitialState = {
    question: '',
    operator: '',
    value: '',
    type: '',
    options: [],
    rowOperator: '',
  };
  const [queries, setQueries] = useState([{ ...queryInitialState }]);

  const questionValues = allQuestions.map((q) => ({
    displayValue: q.question,
    value: q.elementGlobalId,
  }));
  const questionValuesMap = Object.fromEntries(allQuestions.map((q) => [q.elementGlobalId, q]));
  const conditionOperatorsMap = Object.fromEntries(
    conditionOperators.map((c) => [c.value, c.displayValue]),
  );

  const handleChange = (name, value, index) => {
    const tempQueries = [...queries];

    tempQueries[index][name] = value;
    if (name === 'question') {
      const selectedQuestion = questionValuesMap[value];
      tempQueries[index] = {
        ...tempQueries[index],
        type: selectedQuestion.type,
        operator: '',
        value: '',
      };
      if (selectedQuestion.type === 'choice')
        tempQueries[index].options = selectedQuestion.options.map((option) => ({
          value: option.value,
          displayValue: option.responseText,
        }));
      else tempQueries[index].options = [];
    } else if (name === 'rowOperator') {
      tempQueries.forEach((q) => (q.rowOperator = value));
      tempQueries.at(-1).rowOperator = '';
    }
    setQueries(tempQueries);
  };

  const addQuery = () => {
    const tempQueries = [...queries];
    tempQueries.at(-1).rowOperator =
      tempQueries.length === 1 ? 'op_and' : tempQueries.at(-2).rowOperator;

    tempQueries.push({ ...queryInitialState });

    setQueries(tempQueries);
  };

  const removeQuery = (index) => {
    const tempQueries = [...queries];
    tempQueries.splice(index, 1);

    setQueries(tempQueries);
  };

  const clearQuery = () => setQueries([{ ...queryInitialState }]);

  const submit = () => {
    const filterSubmission = queries.map((query) => ({
      elementGlobalId: query.question,
      operator: query.operator,
      valueText: query.value,
    }));
    const filterOperator = queries.length > 1 ? queries[0].rowOperator : '';

    setQueryFilter((prev) => ({ ...prev, ...{ filterSubmission, filterOperator } }));
  };

  const handleQueryFilterChange = (field, value) => {
    setQueryFilter((prev) => ({ ...prev, [field]: value }));
  };

  const actionFilterChange = (_, value) => {
    setFilterData({ ...filterData, status: value });
  };
  return (
    <div className='submission-filter'>
      <div className='w-100 d-flex gap-3 justify-content-end flex-wrap-reverse'>
        <div className='d-flex flex-grow-1 gap-3'>
          <TextField
            value={searchState.searchText}
            name='searchText'
            handleChange={handleSearchStateChange}
            placeholder='field_search'
            icon={searchIcon}
            debounceFunc={handleQueryFilterChange}
            shrink
          />
          <FormButton
            text='text_filter'
            variant={showQueryFilter ? 'green-1' : 'white-1'}
            onClick={() => setShowQueryFilter(!showQueryFilter)}
            icon={filterGreyIcon}
            iconClasses={showQueryFilter ? 'svg-white' : ''}
          />
          <DateField
            name='fromDate'
            value={queryFilter.fromDate}
            handleChange={handleQueryFilterChange}
            shrink
          />
          <DateField
            name='toDate'
            value={queryFilter.toDate}
            handleChange={handleQueryFilterChange}
            shrink
          />
          <TextField
            value={searchState.searchUser}
            name='searchUser'
            handleChange={handleSearchStateChange}
            placeholder='field_search_user'
            icon={searchIcon}
            debounceFunc={handleQueryFilterChange}
            shrink
          />
          <Export
            type={'submission'}
            screenTab={'all'}
            additionalDetail={{
              exportMode: 'all',
              form: filterData.formId,
              formVersion:
                filterData && filterData.version
                  ? filterData.version
                  : versionsList.length > 0
                  ? versionsList[versionsList.length - 1].value
                  : '',
              submissionDataOf:
                filterData.data === 'live-data'
                  ? 'real_data'
                  : filterData.data === 'test-data'
                  ? 'test_data'
                  : 'archived',
            }}
          />
          <SelectField
            label={
              defaultSupervisorStatusOptionsList.filter(
                (item) => item.value === filterData.status,
              )[0]?.responseText ?? 'select_all'
            }
            variant={getApprovalStatusVariant(filterData.status)}
            handleChange={actionFilterChange}
            listValues={supervisorStatusValues}
            icon={filterData.status === '' ? sortingGreyIcon : sortingIcon}
            isAutoClose={true}
            positionReversed
            classes={'sorting m-0 rounded-3'}
          />
          <SubmissionActions
            ids={selectedRows}
            isActionButton={false}
            type={'submissions_actions'}
            actionsCallBack={actionsCallBack}
            otherDetail={{
              listType: filterData.data,
              formId: filterData.formId,
              formVersion:
                filterData && filterData.version
                  ? filterData.version
                  : versionsList.length > 0
                  ? versionsList[versionsList.length - 1].value
                  : '',
            }}
          />
        </div>
        <div className='position-relative'>
          <FormButton
            text='button_show_filter'
            variant={showFilterModal ? 'green-1' : 'white-1'}
            icon={filterLinesGreyIcon}
            iconClasses={showFilterModal ? 'svg-white' : ''}
            iconRight
            onClick={() => setShowFilterModal(!showFilterModal)}
          />
          {showFilterModal && (
            <SubmissionFilterModal
              setShowFilterModal={setShowFilterModal}
              versionsList={versionsList}
              filterData={filterData}
              setFilterData={setFilterData}
              showAttachment={showAttachment}
              setShowAttachment={setShowAttachment}
            />
          )}
        </div>
      </div>
      {showQueryFilter && (
        <div className='query-filter'>
          {queries.map((query, index) => {
            return (
              <div key={index} className='query-row d-flex flex-wrap gap-3 mb-3'>
                <SelectField
                  name='question'
                  label={questionValuesMap[query.question]?.question ?? 'select_question'}
                  handleChange={(field, value) => handleChange(field, value, index)}
                  listValues={questionValues}
                  icon={selectArrow}
                  isAutoClose={true}
                  variant='white-1'
                  menuClasses='width-max-content'
                  ellipseSelected
                />
                <SelectField
                  name='operator'
                  label={
                    (query.type === 'number'
                      ? numberOperators
                      : query.type === 'string'
                      ? stringOperators
                      : query.type === 'multi-choice'
                      ? multiChoiceOperators
                      : query.type === 'single-choice'
                      ? singleChoiceOperators
                      : []
                    ).find((o) => o.value === query.operator)?.displayValue ?? 'select_operator'
                  }
                  handleChange={(field, value) => handleChange(field, value, index)}
                  listValues={
                    query.type === 'number'
                      ? numberOperators
                      : query.type === 'string'
                      ? stringOperators
                      : query.type === 'multi-choice'
                      ? multiChoiceOperators
                      : query.type === 'single-choice'
                      ? singleChoiceOperators
                      : []
                  }
                  icon={selectArrow}
                  isAutoClose={true}
                  variant='white-1'
                  menuClasses='w-100'
                />

                {query.type === 'choice' ? (
                  <SelectField
                    name='value'
                    label={
                      query.options.find((item) => item.value === query.value)?.displayValue ??
                      'SelectValue'
                    }
                    handleChange={(field, value) => handleChange(field, value, index)}
                    listValues={query.options}
                    icon={selectArrow}
                    position={'end'}
                    isAutoClose={true}
                    variant='white-1'
                    menuClasses='w-100'
                  />
                ) : (
                  <TextField
                    placeholder='field_enter_value'
                    name='value'
                    value={query.value}
                    handleChange={(field, value) => handleChange(field, value, index)}
                    shrink
                  />
                )}

                {index < queries.length - 1 && (
                  <SelectField
                    name='rowOperator'
                    label={conditionOperatorsMap[query.rowOperator] ?? 'SelectOperator'}
                    handleChange={(field, value) => handleChange(field, value, index)}
                    listValues={conditionOperators}
                    icon={selectArrow}
                    isAutoClose={true}
                    variant='white-1'
                    menuClasses='w-100'
                  />
                )}

                <div className='d-flex gap-2'>
                  {index + 1 === queries.length && (
                    <div>
                      <div
                        className='icon-div theme-background-green-1 cursor-pointer'
                        onClick={addQuery}
                      >
                        <img src={plusIcon} alt='add' className='svg-white' />
                      </div>
                    </div>
                  )}

                  {queries.length > 1 && (
                    <img
                      src={removeIcon}
                      alt='remove'
                      className='cursor-pointer'
                      onClick={() => removeQuery(index)}
                    />
                  )}
                </div>
              </div>
            );
          })}

          <div className='d-flex gap-3'>
            <FormButton text='button_apply_query' variant='green-1' onClick={submit} />
            <FormButton text='button_clear_query' variant='grey-1' onClick={clearQuery} />
          </div>
        </div>
      )}
    </div>
  );
};

SubmissionFilters.propTypes = {
  allQuestions: PropTypes.array.isRequired,
  versionsList: PropTypes.array.isRequired,
  filterData: PropTypes.object.isRequired,
  setFilterData: PropTypes.func.isRequired,
  showAttachment: PropTypes.string.isRequired,
  setShowAttachment: PropTypes.func.isRequired,
  queryFilter: PropTypes.object.isRequired,
  setQueryFilter: PropTypes.func.isRequired,
  searchState: PropTypes.object.isRequired,
  handleSearchStateChange: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  actionsCallBack: PropTypes.func.isRequired,
};

const SubmissionFilterModal = ({
  setShowFilterModal,
  versionsList,
  filterData,
  setFilterData,
  showAttachment,
  setShowAttachment,
}) => {
  const { t } = useTranslation();

  const [values, setValues] = useState({
    data: filterData.data,
    version: filterData.version,
    showAttachment,
  });
  const filteredVersionList =
    values.data !== 'test-data'
      ? [...versionsList].filter((v) => v.formState === 'published')
      : [...versionsList];
  const handleChange = (field, value) => {
    if (field === 'data' && (value === 'live-data' || value === 'test-data')) {
      setValues({ ...values, [field]: value, version: '' });
    } else setValues({ ...values, [field]: value });
  };
  const applyFilter = () => {
    setShowAttachment(values.showAttachment);
    if (filterData.data !== values.data || filterData.version !== values.version) {
      setFilterData({ ...filterData, data: values.data, version: values.version });
    }
    setShowFilterModal(false);
  };

  return (
    <div className='filter-modal'>
      <div className='d-flex gap-2 justify-content-around my-2'>
        <label
          className='theme-text-red-1 cursor-pointer'
          onClick={() => setShowFilterModal(false)}
        >
          {t('text_cancel')}
        </label>
        <label className='theme-text-green-1 cursor-pointer' onClick={() => applyFilter()}>
          {t('text_filter')}
        </label>
      </div>
      <div className='theme-background-white-3 px-3 py-1'>
        <label className='theme-text-black-1 theme-font-jakartaSans-medium'>
          {t('text_show_attached')}
        </label>
      </div>
      <div className='py-2 px-3'>
        <SingleRadioBoxField
          name='showAttachment1'
          value={values.showAttachment === 'none'}
          handleChange={() => handleChange('showAttachment', 'none')}
          text={<span>{t('text_none')}</span>}
          classes='mb-2'
        />
        <SingleRadioBoxField
          name='showAttachment2'
          value={values.showAttachment === 'attachment'}
          handleChange={() => handleChange('showAttachment', 'attachment')}
          text={<span>{t('text_attachment')}</span>}
        />
      </div>
      <div className='theme-background-white-3 px-3 py-1'>
        <label className='theme-text-black-1 theme-font-jakartaSans-medium'>
          {t('text_show_data')}
        </label>
      </div>
      <div className='py-2 px-3'>
        <SingleRadioBoxField
          name='showData1'
          value={values.data === 'live-data'}
          handleChange={() => handleChange('data', 'live-data')}
          text={<span>{t('text_live_data')}</span>}
          classes='mb-2'
        />
        <SingleRadioBoxField
          name='showData2'
          value={values.data === 'test-data'}
          handleChange={() => handleChange('data', 'test-data')}
          text={<span>{t('text_test_data')}</span>}
          classes='mb-2'
        />
        <SingleRadioBoxField
          name='showData3'
          value={values.data === 'archive-data'}
          handleChange={() => handleChange('data', 'archive-data')}
          text={<span>{t('text_archive_data')}</span>}
        />
      </div>
      <div className='theme-background-white-3 px-3 py-1'>
        <label className='theme-text-black-1 theme-font-jakartaSans-medium'>
          {t('text_version_number')}
        </label>
      </div>
      <div className='py-2 px-3 version-list'>
        {filteredVersionList.map((v, index) => (
          <SingleRadioBoxField
            key={index}
            name={`version${index + 1}`}
            value={values.version === v.value}
            handleChange={() => handleChange('version', v.value)}
            text={
              <span>
                {v.value === '' ? t('text_all') : `${t('text_version')} ${v.displayValue}`}
              </span>
            }
            classes={index < filteredVersionList.length - 1 ? 'mb-2' : ''}
          />
        ))}
      </div>
    </div>
  );
};

SubmissionFilterModal.propTypes = {
  setShowFilterModal: PropTypes.func.isRequired,
  versionsList: PropTypes.array.isRequired,
  filterData: PropTypes.object.isRequired,
  setFilterData: PropTypes.func.isRequired,
  showAttachment: PropTypes.string.isRequired,
  setShowAttachment: PropTypes.func.isRequired,
};

export default SubmissionFilters;
