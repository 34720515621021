import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DropZone = ({ onComponentDrop, clearDrag, shrink, outsideSection, dropAllowed }) => {
  const { t } = useTranslation();
  return (
    <div className={`drop-zone${shrink ? ' shrunk' : ''}`}>
      <div
        className={`drop-area${shrink ? ' shrunk' : ''}`}
        onDragEnter={(e) => {
          e.preventDefault();
          if (dropAllowed) e.target.classList.add('drop-area-hovered');
        }}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={(e) => e.target.classList.remove('drop-area-hovered')}
        onDrop={(e) => {
          e.preventDefault();
          e.target.classList.remove('drop-area-hovered');
          if (dropAllowed) onComponentDrop();
          else clearDrag();
        }}
      >
        {t('form_drop_here')}
        {outsideSection ? ' Outside Section' : ''}
      </div>
    </div>
  );
};

DropZone.propTypes = {
  shrink: PropTypes.bool,
  outsideSection: PropTypes.bool,
  onComponentDrop: PropTypes.func.isRequired,
  dropAllowed: PropTypes.bool.isRequired,
  clearDrag: PropTypes.func.isRequired,
};

export default DropZone;
