import companyIcon from '../../assets/images/icons/company.svg';
import companyDetails from '../../assets/images/icons/company-detail.svg';
import companyDetailsWhite from '../../assets/images/icons/company-detail-white.svg';
import companySettings from '../../assets/images/icons/company-settings.svg';
import companySettingsWhite from '../../assets/images/icons/company-settings-white.svg';
import ownerDetails from '../../assets/images/icons/owner-detail.svg';
import ownerDetailsWhite from '../../assets/images/icons/owner-detail-white.svg';
import additionalDetails from '../../assets/images/icons/additional-detail.svg';
import additionalDetailsWhite from '../../assets/images/icons/additional-detail-white.svg';
import brandLogo from '../../assets/images/icons/brand-logo.svg';
import brandLogoWhite from '../../assets/images/icons/brand-logo-white.svg';
import colorScheme from '../../assets/images/icons/color-scheme.svg';
import colorSchemeWhite from '../../assets/images/icons/color-scheme-white.svg';
import appScreen from '../../assets/images/icons/app-screen.svg';
import appScreenWhite from '../../assets/images/icons/app-screen-white.svg';
import * as yup from 'yup';

export const tabList = [
  {
    id: 1,
    title: 'tabs_general',
    isSelected: true,
    breadcrumbValue: { icon: companyIcon, items: ['sidebar_company_setup', 'tabs_general'] },
    subTabs: [
      {
        id: 'sub1',
        title: 'tabs_company_details',
        isSelected: false,
        component: 'CompanyDetails',
        name: 'CompanyDetails',
        icon: companyDetails,
        selectedIcon: companyDetailsWhite,
        breadcrumbValue: {
          icon: companyIcon,
          items: ['sidebar_company_setup', 'tabs_general', 'tabs_company_details'],
        },
      },
      {
        id: 'sub2',
        title: 'tabs_company_settings',
        isSelected: false,
        name: 'CompanySettings',
        icon: companySettings,
        selectedIcon: companySettingsWhite,
        breadcrumbValue: {
          icon: companyIcon,
          items: ['sidebar_company_setup', 'tabs_general', 'tabs_company_settings'],
        },
      },
      {
        id: 'sub3',
        title: 'tabs_owner_super_admin_details',
        isSelected: false,
        name: 'SuperAdminDetails',
        icon: ownerDetails,
        selectedIcon: ownerDetailsWhite,
        breadcrumbValue: {
          icon: companyIcon,
          items: ['sidebar_company_setup', 'tabs_general', 'tabs_owner_super_admin_details'],
        },
      },
      {
        id: 'sub4',
        title: 'tabs_additional_details',
        isSelected: false,
        name: 'AdditionalDetails',
        icon: additionalDetails,
        selectedIcon: additionalDetailsWhite,
        breadcrumbValue: {
          icon: companyIcon,
          items: ['sidebar_company_setup', 'tabs_general', 'tabs_additional_details'],
        },
      },
    ],
  },
  {
    id: 2,
    title: 'tabs_branding',
    isSelected: false,
    breadcrumbValue: { icon: companyIcon, items: ['sidebar_company_setup', 'tabs_branding'] },
    subTabs: [
      {
        id: 'sub1',
        title: 'tabs_brand_logo',
        isSelected: false,
        name: 'BrandLogo',
        icon: brandLogo,
        selectedIcon: brandLogoWhite,
        breadcrumbValue: {
          icon: companyIcon,
          items: ['sidebar_company_setup', 'tabs_branding', 'tabs_brand_logo'],
        },
      },
      {
        id: 'sub2',
        title: 'tabs_color_scheme',
        isSelected: false,
        name: 'ColorScheme',
        icon: colorScheme,
        selectedIcon: colorSchemeWhite,
        breadcrumbValue: {
          icon: companyIcon,
          items: ['sidebar_company_setup', 'tabs_branding', 'tabs_color_scheme'],
        },
      },
      {
        id: 'sub3',
        title: 'tabs_app_welcome_screen',
        isSelected: false,
        name: 'AppWelcomeScreen',
        icon: appScreen,
        selectedIcon: appScreenWhite,
        breadcrumbValue: {
          icon: companyIcon,
          items: ['sidebar_company_setup', 'tabs_branding', 'tabs_app_welcome_screen'],
        },
      },
    ],
  },
  // {
  //   id: 3,
  //   title: 'Permission',
  //   isSelected: false,
  //   breadcrumbValue: { icon: companyIcon, items: ['Company', 'Permissions'] },
  // },
  // {
  //   id: 4,
  //   title: 'Security',
  //   isSelected: false,
  //   breadcrumbValue: { icon: companyIcon, items: ['Company', 'Security'] },
  // },
];

export const companyDetailSchema = yup.object({
  domain: yup.string().required('Email domain is required'),
});
