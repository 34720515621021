import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import backgroundIcon from 'assets/images/icons/brand-logo-black.svg';
// import trashIcon from 'assets/images/icons/trash-red.svg';
import Error from 'components/Error';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import Loader from 'components/common-components/Loader';
import UploadSectionImage from 'components/common-components/UploadSectionImage';
import { uploadImage } from 'pages/company/utils';
import { CallAPI } from 'actions/General';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';

const BrandLogo = ({ companyDetails, getCompanyDetail }) => {
  const { t } = useTranslation();
  const [newLogo, setNewLogo] = useState(null);
  const [oldLogo, setOldLogo] = useState(
    companyDetails?.media
      ? {
          logo: companyDetails?.media?.downloadUrl,
          mediaId: companyDetails?.media?._id,
          name: companyDetails?.media?.imageName,
          customFolderId: companyDetails?.media?.customFolderId,
        }
      : null,
  );
  useEffect(() => {
    setOldLogo(
      companyDetails?.media
        ? {
            logo: companyDetails?.media?.downloadUrl,
            mediaId: companyDetails?.media?._id,
            name: companyDetails?.media?.imageName,
            customFolderId: companyDetails?.media?.customFolderId,
          }
        : null,
    );
  }, [companyDetails]);

  const [deleteLoader, setDeleteLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [APIError, setAPIError] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewLogo(file);
    }
  };

  const deleteLogo = async (refreshDetail = 1) => {
    if (oldLogo) {
      await CallAPI(
        'DELETE_MEDIA',
        {
          folderType: 'logo',
          mediaId: oldLogo.mediaId,
          mediaType: 'image',
          imageName: oldLogo.name,
          formId: null,
          versionId: null,
          customFolderId: oldLogo.customFolderId,
        },
        null,
        setDeleteLoader,
        null,
        null,
      );

      if (refreshDetail) getCompanyDetail();
    }
  };

  const updateLogoStates = async (id, url) => {
    setOldLogo({ mediaId: id, logo: url });
    let response = await CallAPI(
      'UPDATE_COMPANY_LOGO',
      { mediaId: id },
      null,
      setSaveLoader,
      setAPIError,
      null,
    );
    if (response.status) {
      getCompanyDetail();
    }
  };

  const submit = async () => {
    if (oldLogo && oldLogo.mediaId) await deleteLogo(0);
    let response = await CallAPI(
      'UPLOAD_COMPANY_LOGO',
      { imageName: uuidv4() + '-' + newLogo.name, mediaType: 'image' },
      null,
      setSaveLoader,
      null,
      null,
    );
    if (response.status) {
      await uploadImage(newLogo, response.data, updateLogoStates);
    }
  };

  return (
    <div className='branding'>
      {APIError && <Error msg={APIError} />}
      <div className='brand-logo'>
        <div>
          <div className='theme-size-1_3'>{t('text_add_brand_logo')}</div>
          <div className='theme-size-0_9 mb-2'>{t('text_replace_midani_logo')}</div>
          <UploadSectionImage
            selectedImage={newLogo}
            handleFileChange={handleFileChange}
            label='field_upload_your_logo'
          />
        </div>
        <div>
          <div className='theme-size-1_3'>{t('text_logo')}</div>
          <div className='theme-size-0_9 mb-2'>{t('text_live_preview_of_logo')}</div>
          <div className='preview-box'>
            {deleteLoader ? (
              <Loader color='green-1' />
            ) : (
              <img
                className='preview-image'
                src={oldLogo?.logo ?? backgroundIcon}
                alt='Loading...'
              />
            )}
            {/* <img
              className='position-absolute top-50 end-0 translate-middle-y cursor-pointer'
              src={trashIcon}
              onClick={deleteLogo}
              alt='Loading...'
            /> */}
          </div>
        </div>
      </div>
      {isPermissionAvailable(permissionKeys.system_company_setup_update) && (
        <div className='d-flex justify-content-end gap-3 mx-5 my-3'>
          <FormLoadingButton
            text='button_save'
            variant='green-1'
            onClick={submit}
            loading={saveLoader}
            disabled={newLogo && newLogo.name ? false : true}
          />
        </div>
      )}
    </div>
  );
};
BrandLogo.propTypes = {
  companyDetails: PropTypes.object.isRequired,
  getCompanyDetail: PropTypes.func.isRequired,
};
export default BrandLogo;
