import calendarDateTimeIcon from 'assets/images/icons/calendar-date-time.svg';
import checkListIcon from 'assets/images/icons/check-list.svg';
import checkDoneIcon from 'assets/images/icons/check-done.svg';
import databaseExportIcon from 'assets/images/icons/database-export.svg';
import dataFlowIcon from 'assets/images/icons/data-flow.svg';
import hashIcon from 'assets/images/icons/hash.svg';
import infoSquareIcon from 'assets/images/icons/info-square.svg';
import imageIcon from 'assets/images/icons/image.svg';
import locationIcon from 'assets/images/icons/location.svg';
import noteIcon from 'assets/images/icons/note.svg';
import numberSliderIcon from 'assets/images/icons/number-slider.svg';
// import pageIcon from 'assets/images/icons/page.svg';
import sectionIcon from 'assets/images/icons/section.svg';
import signatureIcon from 'assets/images/icons/signature.svg';
import starIcon from 'assets/images/icons/star.svg';
import textIcon from 'assets/images/icons/text.svg';
import qrCodeGreenIcon from 'assets/images/icons/qr-code-green.svg';
// import micIcon from 'assets/images/icons/mic.svg';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import userRightIcon from 'assets/images/icons/user-right.svg';
// import { DEV_ONLY } from 'config/config';
import { getApprovalStatusVariant } from 'pages/submissions/utils';
import { DEV_ONLY } from 'config/config';

export const fieldCategoryList = [
  { name: 'user-field', text: 'form_user_fields' },
  { name: 'supervisor-field', text: 'form_supervisor_fields' },
];

export const rightToggleList = [
  {
    id: 1,
    defaultPropsKey: 'basic',
    title: 'form_basic_properties',
    isOpen: true,
    nestedOption: null,
    excludedFor: [],
  },
  {
    id: 2,
    title: 'form_advance_options',
    isOpen: false,
    excludedFor: [],
    nestedOption: [
      {
        id: 1,
        title: 'form_data_validation',
        defaultPropsKey: 'validation',
        isOpen: false,
        nestedOption: null,
        excludedFor: ['image'],
      },
      {
        id: 2,
        title: 'form_layout_styling',
        defaultPropsKey: 'layout',
        isOpen: false,
        nestedOption: null,
        excludedFor: [
          'supervisor_note',
          'supervisor_number',
          'supervisor_dateTime',
          'supervisor_signature',
          'supervisor_ratingSlider',
          'supervisor_status',
          'supervisor_response',
        ],
      },
      {
        id: 3,
        title: 'form_media_notes',
        defaultPropsKey: 'media',
        isOpen: false,
        nestedOption: null,
        excludedFor: ['page', 'section', 'image', 'supervisor_section'],
      },
      {
        id: 4,
        title: 'form_others',
        defaultPropsKey: 'other',
        isOpen: false,
        nestedOption: null,
        excludedFor: ['page', 'section', 'image', 'supervisor_section'],
      },
    ],
  },
];
export const userFieldList = [
  { elementType: 'text', displayName: 'form_text', icon: textIcon },
  { elementType: 'number', displayName: 'form_number', icon: hashIcon },
  { elementType: 'checkList', displayName: 'form_choice_list', icon: checkListIcon },
  { elementType: 'image', displayName: 'form_image', icon: imageIcon },
  { elementType: 'qrCode', displayName: 'form_qr_code', icon: qrCodeGreenIcon },
  // ...(DEV_ONLY ? [{ elementType: 'audio', displayName: 'form_audio', icon: micIcon }] : []),
  { elementType: 'location', displayName: 'form_location', icon: locationIcon },
  { elementType: 'dateTime', displayName: 'form_date_time', icon: calendarDateTimeIcon },
  { elementType: 'signature', displayName: 'form_signature', icon: signatureIcon },
  { elementType: 'ratingSlider', displayName: 'form_star_rating', icon: starIcon },
  { elementType: 'numberSlider', displayName: 'form_number_slider', icon: numberSliderIcon },
  // { elementType: 'page', displayName: 'form_page', icon: pageIcon },
  { elementType: 'section', displayName: 'form_section', icon: sectionIcon },
  { elementType: 'apiStatus', displayName: 'form_api_status', icon: infoSquareIcon },
  { elementType: 'dataSource', displayName: 'form_data_source', icon: databaseExportIcon },
  { elementType: 'externalApi', displayName: 'form_external_api', icon: dataFlowIcon },
  { elementType: 'sendTo', displayName: 'form_send_to', icon: userRightIcon },
];

export const supervisorFieldList = [
  { elementType: 'supervisor_note', displayName: 'form_note', icon: noteIcon },
  { elementType: 'supervisor_number', displayName: 'form_number', icon: hashIcon },
  { elementType: 'supervisor_dateTime', displayName: 'form_date_time', icon: calendarDateTimeIcon },
  { elementType: 'supervisor_signature', displayName: 'form_signature', icon: signatureIcon },
  { elementType: 'supervisor_ratingSlider', displayName: 'form_star_rating', icon: starIcon },
  ...(DEV_ONLY
    ? [
        {
          elementType: 'supervisor_section',
          displayName: 'form_manager_section',
          icon: sectionIcon,
        },
      ]
    : []),
  { elementType: 'supervisor_status', displayName: 'form_status', icon: infoSquareIcon },
  ...(DEV_ONLY
    ? [
        {
          elementType: 'supervisor_response',
          displayName: 'form_manager_response',
          icon: checkDoneIcon,
        },
      ]
    : []),
];
export const defaultResponseSet = [
  [
    { responseText: 'Yes', value: '2', itemColor: '#38cb89ff' },
    { responseText: 'No', value: '1', itemColor: '#ec3434ff' },
    { responseText: 'Maybe', value: '0', itemColor: '#83898cff' },
  ],
  [
    { responseText: 'Good', value: '3', itemColor: '#38cb89ff' },
    { responseText: 'Fair', value: '2', itemColor: '#ffa600ff' },
    { responseText: 'Poor', value: '1', itemColor: '#ec3434ff' },
    { responseText: 'N/A', value: '0', itemColor: '#83898cff' },
  ],
  [
    { responseText: 'Pass', value: '2', itemColor: '#38cb89ff' },
    { responseText: 'Fail', value: '1', itemColor: '#ec3434ff' },
    { responseText: 'N/A', value: '0', itemColor: '#83898cff' },
  ],
  [
    { responseText: 'Compliant', value: '2', itemColor: '#38cb89ff' },
    { responseText: 'Non-Compliant', value: '1', itemColor: '#ec3434ff' },
    { responseText: 'N/A', value: '0', itemColor: '#83898cff' },
  ],
];

const dataSourceDisplayOptionValues = [
  { value: 'open', displayValue: 'form_field_open' },
  { value: 'dropdown', displayValue: 'form_field_drop_down_menu' },
  { value: 'auto-complete', displayValue: 'form_field_auto_complete_search' },
];

const externalApiDisplayOptionValues = [{ value: 'open', displayValue: 'form_field_open' }];

const fieldLayoutValues = [
  { value: 'horizontal', displayValue: 'form_field_horizontal', isNested: false },
  { value: 'vertical', displayValue: 'form_field_vertical', isNested: false },
];
const keyboardStyleValues = [
  { value: 'keyboard', displayValue: 'form_field_keyboard', isNested: false },
  { value: 'keypad', displayValue: 'form_field_keypad', isNested: false },
];
const textLinesValues = [
  { value: 'single', displayValue: 'form_field_single_line', isNested: false },
  { value: 'multiple', displayValue: 'form_field_multi_line', isNested: false },
];
const answerFormatValues = [
  { value: 'description', displayValue: 'form_field_description', isNested: false },
  { value: 'email', displayValue: 'form_field_email_address', isNested: false },
  { value: 'localNumber', displayValue: 'form_field_local_phone', isNested: false },
  {
    value: 'internationalNumber',
    displayValue: 'form_field_international_phone',
    isNested: false,
  },
  { value: 'webAddress', displayValue: 'form_field_web_address', isNested: false },
];
const numberTypeValues = [
  { value: 'integer', displayValue: 'form_field_integer', isNested: false },
  { value: 'decimal', displayValue: 'form_field_decimal', isNested: false },
];
const dateTimeSubValues = [
  { value: 'dateAndTime', displayValue: 'form_field_date_time', isNested: false },
  { value: 'date', displayValue: 'form_field_date', isNested: false },
  { value: 'time', displayValue: 'form_field_time', isNested: false },
];
const mapTypeValues = [
  { value: 'standard', displayValue: 'form_map_standard', isNested: false },
  { value: 'satellite', displayValue: 'form_map_satellite', isNested: false },
  { value: 'hybrid', displayValue: 'form_map_hybrid', isNested: false },
];
const accuracyValues = [
  { value: '5', displayValue: '5', isNested: false },
  { value: '10', displayValue: '10', isNested: false },
  { value: '25', displayValue: '25', isNested: false },
  { value: '50', displayValue: '50', isNested: false },
  { value: '100', displayValue: '100', isNested: false },
];
const dropDownTypeValues = [
  { value: 'dropdown', displayValue: 'form_field_dropdown_list', isNested: false },
  { value: 'choiceList', displayValue: 'form_field_list_of_choices', isNested: false },
];
const dataSourceDataTypeValues = [
  { value: 'text', displayValue: 'select_text', isNested: false },
  { value: 'number', displayValue: 'select_number', isNested: false },
];
const dataSourceTypeValues = [
  { value: 'internal', displayValue: 'select_internal_data_source', isNested: false },
  { value: 'external', displayValue: 'select_external_data_source', isNested: false },
];
const sendToValues = [
  { value: 'email', displayValue: 'form_field_dropdown_email', isNested: false },
  { value: 'sms', displayValue: 'form_field_dropdown_sms', isNested: false },
  { value: 'whatsapp', displayValue: 'form_field_dropdown_whatsapp', isNested: false },
];
export const APIMethod = [
  { value: 'post', displayValue: 'form_field_post', isNested: false },
  { value: 'get', displayValue: 'form_field_get', isNested: false },
];
export const AuthType = [
  { value: 'bearer', displayValue: 'form_field_bearer_auth', isNested: false },
  { value: 'api_auth', displayValue: 'form_field_api_auth', isNested: false },
];
export const ApiParamsTypes = [
  { value: 'url', displayValue: 'form_field_url_param', isNested: false },
  { value: 'query', displayValue: 'form_field_query_param', isNested: false },
];

export const DataTypeValues = [
  { value: 'string', displayValue: 'form_field_string', isNested: false },
  { value: 'number', displayValue: 'form_field_number', isNested: false },
  { value: 'date_time', displayValue: 'form_field_date_time', isNested: false },
  { value: 'boolean', displayValue: 'form_field_boolean', isNested: false },
];
export const defaultSupervisorStatusOptionsList = [
  { responseText: 'Ready', value: 'ready', itemColor: '#38CB89FF' },
  { responseText: 'Not Ready', value: 'not_ready', itemColor: '#ec3434ff' },
  { responseText: 'In Complete', value: 'incomplete', itemColor: '#ffa600ff' },
];

export const supervisorStatusValues = [
  {
    value: '',
    displayValue: `select_all`,
    variant: 'white-1',
  },
  ...defaultSupervisorStatusOptionsList.map((s) => ({
    value: s.value,
    displayValue: `select_${s.value}`,
    variant: getApprovalStatusVariant(s.value),
  })),
];
// export const BindingTypeValues = [
//   { value: 'element', displayValue: 'form_field_element', isNested: false },
//   { value: 'system', displayValue: 'form_field_system', isNested: false },
// ];

// For triggers and workflows
export const isValues = [
  { value: 'is', displayValue: 'is' },
  { value: 'is_not', displayValue: 'is not' },
];

// For All Available field type isAllowedForAll = true
// If Some are not allowed to view isAllowedForAll = true and RestrictedFieldType array for not visible elements array
// If available for only some field then it available elements will be in allowedFieldTypes array
export const fieldTypeAllowedProperties = {
  basic: [
    {
      type: 'textWithColor',
      key: 'fieldTitle',
      text: 'form_field_title',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [
        'page',
        'section',
        'supervisor_note',
        'supervisor_number',
        'supervisor_dateTime',
        'supervisor_signature',
        'supervisor_ratingSlider',
        'supervisor_status',
        'supervisor_response',
        'supervisor_section',
      ],
    },
    {
      type: 'text',
      key: 'fieldTitle',
      text: 'form_field_title',
      isAllowedForAll: false,
      allowedFieldTypes: [
        'supervisor_note',
        'supervisor_number',
        'supervisor_dateTime',
        'supervisor_signature',
        'supervisor_ratingSlider',
        'supervisor_status',
        'supervisor_response',
      ],
      restrictedFieldTypes: [],
    },
    {
      type: 'textWithColor',
      key: 'pageTitle',
      text: 'form_field_title',
      isAllowedForAll: false,
      allowedFieldTypes: ['page'],
      restrictedFieldTypes: [],
    },
    {
      type: 'textWithColor',
      key: 'sectionTitle',
      text: 'form_field_title',
      isAllowedForAll: false,
      allowedFieldTypes: ['section', 'supervisor_section'],
      restrictedFieldTypes: [],
    },
    {
      type: 'textWithColor',
      key: 'sectionHintText',
      text: 'form_field_hint_text',
      isAllowedForAll: false,
      allowedFieldTypes: ['section', 'supervisor_section'],
      restrictedFieldTypes: [],
    },
    {
      type: 'textWithColor',
      key: 'fieldHintText',
      text: 'form_field_hint_text',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [
        'page',
        'section',
        'supervisor_note',
        'supervisor_number',
        'supervisor_dateTime',
        'supervisor_signature',
        'supervisor_ratingSlider',
        'supervisor_status',
        'supervisor_response',
        'supervisor_section',
      ],
    },
    {
      type: 'text',
      key: 'fieldHintText',
      text: 'form_field_hint_text',
      isAllowedForAll: false,
      allowedFieldTypes: [
        'supervisor_note',
        'supervisor_number',
        'supervisor_dateTime',
        'supervisor_signature',
        'supervisor_ratingSlider',
        'supervisor_status',
        'supervisor_response',
      ],
      restrictedFieldTypes: [],
    },
    {
      type: 'text',
      key: 'fieldPlaceholder',
      text: 'form_field_placeholder',
      isAllowedForAll: false,
      allowedFieldTypes: ['text', 'number', 'qrCode', 'dataSource', 'externalApi'],
      restrictedFieldTypes: [],
    },

    {
      type: 'dropdown',
      key: 'dataType',
      text: 'form_field_datasource_data_type',
      placeholder: 'form_field_choices',
      isAllowedForAll: false,
      allowedFieldTypes: ['dataSource', 'externalApi'],
      restrictedFieldTypes: [],
      valuesList: dataSourceDataTypeValues,
      icon: arrowDownIcon,
    },
    {
      type: 'dropdown',
      key: 'subType',
      text: 'form_field_display_option',
      placeholder: 'form_field_choices',
      isAllowedForAll: false,
      allowedFieldTypes: ['dataSource'],
      restrictedFieldTypes: [],
      valuesList: dataSourceDisplayOptionValues,
      icon: arrowDownIcon,
    },
    {
      type: 'dropdown',
      key: 'subType',
      text: 'form_field_display_option',
      placeholder: 'form_field_choices',
      isAllowedForAll: false,
      allowedFieldTypes: ['externalApi'],
      restrictedFieldTypes: [],
      valuesList: externalApiDisplayOptionValues,
      icon: arrowDownIcon,
    },
    {
      type: 'dropdown',
      key: 'dataSourceType',
      text: 'field_data_source_type',
      placeholder: 'form_field_choices',
      isAllowedForAll: false,
      allowedFieldTypes: ['dataSource', 'externalApi'],
      restrictedFieldTypes: [],
      valuesList: dataSourceTypeValues,
      icon: arrowDownIcon,
    },
    {
      type: 'dropdown',
      key: 'subType',
      text: 'form_field_date_time_type',
      placeholder: 'form_field_date_time_type',
      isAllowedForAll: false,
      allowedFieldTypes: ['dateTime', 'supervisor_dateTime'],
      restrictedFieldTypes: [],
      valuesList: dateTimeSubValues,
      icon: arrowDownIcon,
    },
    {
      type: 'checkbox',
      key: 'showHijriDate',
      text: 'form_field_show_hijri_date',
      isAllowedForAll: false,
      allowedFieldTypes: ['dateTime', 'supervisor_dateTime'],
      restrictedFieldTypes: [],
      externalCssApply: true,
    },
    {
      type: 'rating',
      key: 'ratingPointers',
      text: '',
      isAllowedForAll: false,
      allowedFieldTypes: ['ratingSlider', 'supervisor_ratingSlider'],
      restrictedFieldTypes: [],
      highLabelKey: 'highLabel',
      lowLabelKey: 'lowLabel',
    },
    {
      type: 'slider',
      key: 'ratingPointers',
      text: '',
      isAllowedForAll: false,
      allowedFieldTypes: ['numberSlider'],
      restrictedFieldTypes: [],
      minRangeKey: 'minRange',
      maxRangeKey: 'maxRange',
    },
    {
      type: 'dropdown',
      key: 'subType',
      text: 'form_field_choices',
      placeholder: 'form_field_choices',
      isAllowedForAll: false,
      allowedFieldTypes: ['checkList', 'supervisor_response'],
      restrictedFieldTypes: [],
      valuesList: dropDownTypeValues,
      icon: arrowDownIcon,
    },
    {
      type: 'checkbox',
      key: 'multipleChoice',
      text: 'form_field_multiple_choice',
      isAllowedForAll: false,
      allowedFieldTypes: ['checkList'],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'allowEnglishNumbers',
      text: 'form_field_allow_english_numbers',
      isAllowedForAll: false,
      allowedFieldTypes: ['number'],
      restrictedFieldTypes: [],
    },
    {
      type: 'toggle',
      key: 'optionList',
      text: 'form_field_answer_choice',
      isAllowedForAll: false,
      allowedFieldTypes: ['checkList', 'supervisor_response'],
      restrictedFieldTypes: [],
    },
    {
      type: 'toggle',
      key: 'dataSource',
      text: '',
      isAllowedForAll: false,
      allowedFieldTypes: ['dataSource'],
      restrictedFieldTypes: [],
    },
    {
      type: 'toggle',
      key: 'externalApi',
      text: '',
      isAllowedForAll: false,
      allowedFieldTypes: ['externalApi'],
      restrictedFieldTypes: [],
    },
    {
      type: 'dropdown',
      key: 'sendToType',
      text: 'form_field_send_to_dropdown',
      placeholder: 'form_field_send_to_dropdown',
      isAllowedForAll: false,
      allowedFieldTypes: ['sendTo'],
      restrictedFieldTypes: [],
      valuesList: sendToValues,
      icon: arrowDownIcon,
    },
  ],
  other: [
    {
      type: 'text',
      key: 'fieldUniqueId',
      text: 'form_field_field_id',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
    },
    {
      type: 'text',
      key: 'defaultValue',
      text: 'form_field_default_value',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
    },
    {
      type: 'text',
      key: 'shortTitle',
      text: 'form_field_short_title',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'isPersonalData',
      text: 'form_field_is_personal_data',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [
        'supervisor_note',
        'supervisor_number',
        'supervisor_dateTime',
        'supervisor_signature',
        'supervisor_ratingSlider',
        'supervisor_status',
        'supervisor_section',
        'supervisor_response',
      ],
    },
  ],
  media: [
    {
      type: 'checkbox',
      key: 'allowNotes',
      text: 'form_field_allow_notes',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'allowPictures',
      text: 'form_field_allow_pictures',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
      dependantElementsList: [
        {
          type: 'checkbox',
          key: 'requiredPictures',
          text: 'form_field_required_pictures',
          isAllowedForAll: true,
          allowedFieldTypes: [],
          restrictedFieldTypes: [],
        },
        {
          type: 'checkbox',
          key: 'allowPictureUpload',
          text: 'form_field_allow_picture_upload',
          isAllowedForAll: true,
          allowedFieldTypes: [],
          restrictedFieldTypes: [],
        },
        {
          type: 'numberWithTag',
          key: 'minPictures',
          text: 'form_field_min',
          isAllowedForAll: true,
          allowedFieldTypes: [],
          restrictedFieldTypes: [],
          externalCssApply: true,
        },
        {
          type: 'numberWithTag',
          key: 'maxPictures',
          text: 'form_field_max',
          isAllowedForAll: true,
          allowedFieldTypes: [],
          restrictedFieldTypes: [],
          externalCssApply: true,
        },
      ],
    },
    {
      type: 'checkbox',
      key: 'allowVideos',
      text: 'form_field_allow_videos',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
      dependantElementsList: [
        {
          type: 'checkbox',
          key: 'allowVideoUpload',
          text: 'form_field_allow_video_upload',
          isAllowedForAll: true,
          allowedFieldTypes: [],
          restrictedFieldTypes: [],
        },
        {
          type: 'numberWithTag',
          key: 'videoMinutes',
          text: 'form_field_min',
          isAllowedForAll: true,
          allowedFieldTypes: [],
          restrictedFieldTypes: [],
          externalCssApply: true,
        },
        {
          type: 'numberWithTag',
          key: 'videoSeconds',
          text: 'form_field_seconds',
          isAllowedForAll: true,
          allowedFieldTypes: [],
          restrictedFieldTypes: [],
          externalCssApply: true,
        },
      ],
    },
    {
      type: 'checkbox',
      key: 'showTimeStamp',
      text: 'form_field_show_time_stamp',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'showTitle',
      text: 'form_field_show_title',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'restrictCameraOrientation',
      text: 'form_field_restrict_camera_orientation',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
      dependantElementsList: [
        {
          type: 'dropdown',
          key: 'cameraOrientation',
          text: '',
          isAllowedForAll: true,
          allowedFieldTypes: [],
          restrictedFieldTypes: [],
          valuesList: fieldLayoutValues,
          icon: arrowDownIcon,
        },
      ],
    },
    {
      type: 'checkbox',
      key: 'allowDownload',
      text: 'form_field_allow_download_to_gallery',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
    },
  ],
  layout: [
    {
      type: 'ColorPicker',
      key: 'background',
      text: 'form_field_background_color',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
      externalCssApply: true,
    },
    {
      type: 'checkbox',
      key: 'transparent',
      text: 'form_field_transparent',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [],
      externalCssApply: true,
    },
    {
      type: 'ColorPicker',
      key: 'signature',
      text: 'form_field_signature_color',
      isAllowedForAll: false,
      allowedFieldTypes: ['signature'],
      restrictedFieldTypes: [],
      externalCssApply: true,
    },
    {
      type: 'dropdownIcon',
      key: 'ratingIcon',
      text: 'form_field_icon',
      placeholder: 'form_field_icon',
      isAllowedForAll: false,
      allowedFieldTypes: ['ratingSlider'],
      restrictedFieldTypes: [],
      valuesList: fieldLayoutValues,
      icon: arrowDownIcon,
    },
    {
      type: 'dropdown',
      key: 'fieldLayout',
      text: 'form_field_field_layout',
      placeholder: 'form_field_field_layout',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [
        'page',
        'section',
        'signature',
        'numberSlider',
        'ratingSlider',
        'audio',
        'externalApi',
        'apiStatus',
        'sendTo',
        'supervisor_section',
      ],
      valuesList: fieldLayoutValues,
      icon: arrowDownIcon,
      externalCssApply: true,
    },
    {
      type: 'dropdown',
      key: 'keyboardStyle',
      text: 'form_field_keyboard_style',
      placeholder: 'form_field_keyboard_style',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: [
        'dataSource',
        'audio',
        'qrCode',
        'page',
        'section',
        'signature',
        'numberSlider',
        'ratingSlider',
        'image',
        'checkList',
        'apiStatus',
        'externalApi',
        'sendTo',
        'supervisor_section',
      ],
      valuesList: keyboardStyleValues,
      icon: arrowDownIcon,
      externalCssApply: true,
    },
    {
      type: 'dropdown',
      key: 'textLines',
      text: 'form_field_text_lines',
      placeholder: 'form_field_text_lines',
      isAllowedForAll: false,
      allowedFieldTypes: ['text'],
      restrictedFieldTypes: [],
      valuesList: textLinesValues,
      icon: arrowDownIcon,
    },
    {
      type: 'dropdown',
      key: 'answerFormat',
      text: 'form_field_answer_format',
      placeholder: 'form_field_answer_format',
      isAllowedForAll: false,
      allowedFieldTypes: ['text'],
      restrictedFieldTypes: [],
      valuesList: answerFormatValues,
      icon: arrowDownIcon,
    },
    {
      type: 'dropdown',
      key: 'numberType',
      text: 'form_field_number_type',
      placeholder: 'form_field_number_type',
      isAllowedForAll: false,
      allowedFieldTypes: ['number'],
      restrictedFieldTypes: [],
      valuesList: numberTypeValues,
      icon: arrowDownIcon,
    },
    {
      type: 'dropdown',
      key: 'mapType',
      text: 'form_field_display_map_type',
      placeholder: 'form_field_display_map_type',
      isAllowedForAll: false,
      allowedFieldTypes: ['location'],
      restrictedFieldTypes: [],
      valuesList: mapTypeValues,
      icon: arrowDownIcon,
      externalCssApply: true,
    },
    {
      type: 'dropdown',
      key: 'accuracy',
      text: 'form_field_gps_accuracy_in_meters',
      placeholder: 'form_field_gps_accuracy_in_meters',
      isAllowedForAll: false,
      allowedFieldTypes: ['location'],
      restrictedFieldTypes: [],
      valuesList: accuracyValues,
      icon: arrowDownIcon,
      externalCssApply: true,
    },
    {
      type: 'checkbox',
      key: 'withoutFrame',
      text: 'form_field_without_frame',
      isAllowedForAll: false,
      allowedFieldTypes: ['image'],
      restrictedFieldTypes: [],
      externalCssApply: true,
    },
    {
      type: 'checkbox',
      key: 'collapseOnSelect',
      text: 'form_field_collapse_list_on_select',
      isAllowedForAll: false,
      allowedFieldTypes: ['checkList'],
      restrictedFieldTypes: [],
    },
    {
      type: 'imagePicker',
      key: '',
      text: '',
      isAllowedForAll: false,
      allowedFieldTypes: ['image'],
      restrictedFieldTypes: [],
    },
  ],
  validation: [
    {
      type: 'checkbox',
      key: 'required',
      text: 'form_field_required',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: ['page', 'section', 'supervisor_section'],
      disabledOn: ['hidden'],
      disabledCondition: true,
    },
    {
      type: 'checkbox',
      key: 'readOnly',
      text: 'form_field_read_only',
      isAllowedForAll: false,
      allowedFieldTypes: [
        'text',
        'number',
        'location',
        'dateTime',
        'ratingSlider',
        'numberSlider',
        'sendTo',
        'supervisor_note',
        'supervisor_number',
        'supervisor_dateTime',
        'supervisor_ratingSlider',
        'supervisor_response',
      ],
      restrictedFieldTypes: [],
      disabledOn: ['required', 'hidden'],
      disabledFrom: 'basic',
      disabledCondition: true,
    },
    {
      type: 'checkbox',
      key: 'hideOnPdf',
      text: 'form_field_hide_on_pdf',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: ['page'],
      disabledOn: ['required', 'hidden'],
      disabledFrom: 'basic',
      disabledCondition: true,
    },
    {
      type: 'checkbox',
      key: 'showDataStatus',
      text: 'form_field_show_data_status',
      isAllowedForAll: false,
      allowedFieldTypes: ['dataSource', 'externalApi'],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'hidden',
      text: 'form_field_hide_field',
      isAllowedForAll: true,
      allowedFieldTypes: [],
      restrictedFieldTypes: ['supervisor_section', 'page', 'section'],
      disabledOn: ['required'],
      disabledFrom: 'basic',
      disabledCondition: true,
    },
    {
      type: 'checkbox',
      key: 'hidden',
      text: 'form_field_hide_section',
      isAllowedForAll: false,
      allowedFieldTypes: ['section', 'supervisor_section'],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'restriction',
      dependantElementsList: [
        {
          type: 'numberWithTag',
          key: 'minRange',
          text: 'form_field_min',
          isAllowedForAll: false,
          allowedFieldTypes: ['text'],
          restrictedFieldTypes: [],
          externalCssApply: true,
        },
        {
          type: 'numberWithTag',
          key: 'maxRange',
          text: 'form_field_max',
          isAllowedForAll: false,
          allowedFieldTypes: ['text'],
          restrictedFieldTypes: [],
          externalCssApply: true,
        },
      ],
      text: 'form_field_restrict_to_length',
      isAllowedForAll: false,
      allowedFieldTypes: ['text'],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'restriction',
      dependantElementsList: [
        {
          type: 'numberWithTag',
          key: 'minRange',
          text: 'form_field_min',
          isAllowedForAll: false,
          allowedFieldTypes: ['number'],
          restrictedFieldTypes: [],
          externalCssApply: true,
        },
        {
          type: 'numberWithTag',
          key: 'maxRange',
          text: 'form_field_max',
          isAllowedForAll: false,
          allowedFieldTypes: ['number'],
          restrictedFieldTypes: [],
          externalCssApply: true,
        },
      ],
      text: 'form_field_restrict_to_range',
      isAllowedForAll: false,
      allowedFieldTypes: ['number', 'supervisor_number'],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'restriction',
      dependantElementsList: [
        {
          type: 'dateWithTag',
          key: 'minDate',
          text: 'form_field_min',
          isAllowedForAll: false,
          allowedFieldTypes: ['dateTime'],
          restrictedFieldTypes: [],
        },
        {
          type: 'dateWithTag',
          key: 'maxDate',
          text: 'form_field_max',
          isAllowedForAll: false,
          allowedFieldTypes: ['dateTime'],
          restrictedFieldTypes: [],
        },
      ],
      text: 'form_field_date_range',
      isAllowedForAll: false,
      allowedFieldTypes: ['dateTime'],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'sendAfterSubmit',
      text: 'form_field_send_after_submit',
      isAllowedForAll: false,
      allowedFieldTypes: ['sendTo'],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'sendAfterApproval',
      text: 'form_field_send_to',
      isAllowedForAll: false,
      allowedFieldTypes: ['sendTo'],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'showStamp',
      text: 'form_field_show_stamp',
      isAllowedForAll: false,
      allowedFieldTypes: ['location'],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'allowSelectLocation',
      text: 'form_field_allow_select_location',
      isAllowedForAll: false,
      allowedFieldTypes: ['location'],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'allowShareLocation',
      text: 'form_field_allow_share_location',
      isAllowedForAll: false,
      allowedFieldTypes: ['location'],
      restrictedFieldTypes: [],
    },
    {
      type: 'checkbox',
      key: 'validateRequiredFields',
      text: 'form_field_validate_required_fields',
      isAllowedForAll: false,
      allowedFieldTypes: ['page'],
      restrictedFieldTypes: [],
    },
  ],
};

export const defaultCommonProperties = {
  pageBasicProperties: {
    pageTitleColor: '#000000ff',
    pageTitleSize: '16px',
    pageTitleFontType: [],
    pageTitle: '',
  },
  sectionBasicProperties: {
    sectionTitleColor: '#ffffffff',
    sectionTitleSize: '14px',
    sectionTitleFontType: [],
    sectionHintTextColor: '#000000ff',
    sectionHintTextSize: '12px',
    sectionHintTextFontType: [],
    allowMediaNotes: false,
    sectionTitle: '',
    sectionHintText: '',
  },
  fieldBasicProperties: {
    fieldTitleColor: '#000000ff',
    fieldTitleSize: '14px',
    fieldTitleFontType: [],
    fieldHintTextColor: '#000000ff',
    fieldHintTextSize: '12px',
    fieldHintTextFontType: [],
    allowMediaNotes: false,
    fieldTitle: '',
    fieldHintText: '',
  },
  supervisorBasicProperties: {
    fieldTitle: '',
    fieldHintText: '',
  },
  supervisorOtherProperties: {
    fieldUniqueId: '',
    defaultValue: '',
    shortTitle: '',
  },
  otherProperties: {
    fieldUniqueId: '',
    defaultValue: '',
    shortTitle: '',
    isPersonalData: false,
  },
  mediaProperties: {
    allowNotes: false,
    allowPictures: false,
    requiredPictures: false,
    allowPictureUpload: false,
    allowVideoUpload: false,
    allowVideos: false,
    showTimeStamp: false,
    showTitle: false,
    restrictCameraOrientation: false,
    allowDownload: false,
    minPictures: 1,
    maxPictures: 3,
    videoMinutes: 0,
    videoSeconds: 5,
    cameraOrientation: 'vertical',
  },
  layoutProperties: {
    backgroundColor: '#ffffffff',
    transparent: false,
  },
};
// text, number, checkList, image, location, dateTime, signature, ratingSlider, numberSlider, page, section, dataSource, note, status
// new Fields to add, qr code same as text, audio, formulas for (required, hidden, read only)
export const formElementsDefaultProperties = {
  page: {
    basicProperties: {
      ...defaultCommonProperties.pageBasicProperties,
    },
    validationProperties: { validateRequiredFields: true },
    layoutProperties: { ...defaultCommonProperties.layoutProperties },
    otherProperties: { ...defaultCommonProperties.otherProperties },
  },
  text: {
    basicProperties: {
      ...defaultCommonProperties.fieldBasicProperties,
      fieldPlaceholder: '',
    },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
      restriction: false,
      minRange: 1,
      maxRange: 50,
    },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
      textLines: 'single',
      answerFormat: 'description',
      fieldLayout: 'horizontal',
      keyboardStyle: 'keyboard',
    },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  sendTo: {
    basicProperties: {
      ...defaultCommonProperties.fieldBasicProperties,
      sendToType: 'email',
    },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
      sendAfterSubmit: false,
      sendAfterApproval: false,
    },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
    },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  number: {
    basicProperties: {
      ...defaultCommonProperties.fieldBasicProperties,
      fieldPlaceholder: '',
      allowEnglishNumbers: false,
    },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
      restriction: false,
      minRange: 1,
      maxRange: 10,
    },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
      numberType: 'integer',
      fieldLayout: 'horizontal',
      keyboardStyle: 'keyboard',
    },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  dateTime: {
    basicProperties: {
      ...defaultCommonProperties.fieldBasicProperties,
      subType: 'date',
      showHijriDate: false,
    },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
      restriction: false,
      minDate: '',
      maxDate: '',
    },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
      fieldLayout: 'horizontal',
      keyboardStyle: 'keyboard',
    },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  location: {
    basicProperties: { ...defaultCommonProperties.fieldBasicProperties },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
      showStamp: false,
      allowSelectLocation: false,
      allowShareLocation: false,
    },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
      mapType: 'standard',
      accuracy: '5',
      fieldLayout: 'horizontal',
      keyboardStyle: 'keyboard',
    },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  signature: {
    basicProperties: { ...defaultCommonProperties.fieldBasicProperties },
    validationProperties: {
      required: false,
      hidden: false,
      hideOnPdf: false,
    },
    layoutProperties: { ...defaultCommonProperties.layoutProperties, signatureColor: '#000000ff' },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  ratingSlider: {
    basicProperties: {
      ...defaultCommonProperties.fieldBasicProperties,
      lowLabel: 'Bad',
      highLabel: 'Good',
      ratingPointers: 5,
    },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
    },
    layoutProperties: { ...defaultCommonProperties.layoutProperties, ratingIcon: 'star' },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  numberSlider: {
    basicProperties: { ...defaultCommonProperties.fieldBasicProperties, minRange: 1, maxRange: 10 },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
    },
    layoutProperties: { ...defaultCommonProperties.layoutProperties },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  section: {
    basicProperties: { ...defaultCommonProperties.sectionBasicProperties },
    validationProperties: { required: false, hidden: false, hideOnPdf: false },
    layoutProperties: { ...defaultCommonProperties.layoutProperties, backgroundColor: '#38cb89ff' },
    otherProperties: { ...defaultCommonProperties.otherProperties },
  },
  dataSource: {
    basicProperties: {
      ...defaultCommonProperties.fieldBasicProperties,
      subType: 'dropdown',
      fieldPlaceholder: '',
      dataType: 'text',
      dataSourceType: 'internal',
      dataSource: '',
      displayColumn: '',
      valueColumn: '',
      bindingList: [],
    },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
      fieldLayout: 'horizontal',
      collapseOnSelect: false,
    },
    validationProperties: {
      required: false,
      showDataStatus: true,
      hidden: false,
      hideOnPdf: false,
    },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  image: {
    basicProperties: { ...defaultCommonProperties.fieldBasicProperties },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
      fieldLayout: 'horizontal',
      withoutFrame: false,
      fieldImageFile: null,
      fieldImage: null,
    },
  },
  checkList: {
    basicProperties: {
      ...defaultCommonProperties.fieldBasicProperties,
      subType: 'choiceList',
      multipleChoice: false,
      optionList: [],
    },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
      fieldLayout: 'horizontal',
      collapseOnSelect: false,
    },
    validationProperties: {
      required: false,
      hidden: false,
      hideOnPdf: false,
    },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  apiStatus: {
    basicProperties: {
      ...defaultCommonProperties.fieldBasicProperties,
      subType: 'choiceList',
      optionList: [
        {
          responseText: 'Found',
          value: 'found',
          itemColor: '#38CB89FF',
        },
        {
          responseText: 'Not Found',
          value: 'not_found',
          itemColor: '#EC3434FF',
        },
      ],
    },
    validationProperties: {
      required: false,
      hidden: false,
      hideOnPdf: false,
    },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
    },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  externalApi: {
    basicProperties: {
      ...defaultCommonProperties.fieldBasicProperties,
      subType: 'open',
      fieldPlaceholder: '',
      dataType: 'text',
      dataSourceType: 'external',
      apiMethod: 'post',
      apiParamType: 'url',
      apiParamValue: '',
      apiUrl: '',
      bindingList: [],
      auth: {
        authType: 'api_auth',
        authValue: '',
        authApiUrl: '',
        authApiMethod: 'post',
        responseAuthKeyName: '',
        parameters: { client_name: '', client_id: '', client_secret: '' },
      },
    },
    validationProperties: {
      required: false,
      showDataStatus: true,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
    },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
      fieldLayout: 'horizontal',
    },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  qrCode: {
    basicProperties: {
      ...defaultCommonProperties.fieldBasicProperties,
      fieldPlaceholder: '',
    },
    validationProperties: {
      required: false,
      hidden: false,
      hideOnPdf: false,
    },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
      textLines: 'single',
      fieldLayout: 'horizontal',
    },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  audio: {
    basicProperties: {
      ...defaultCommonProperties.fieldBasicProperties,
    },
    validationProperties: {
      required: false,
      hidden: false,
      hideOnPdf: false,
    },
    layoutProperties: {
      ...defaultCommonProperties.layoutProperties,
    },
    otherProperties: { ...defaultCommonProperties.otherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },

  supervisor_number: {
    basicProperties: {
      ...defaultCommonProperties.supervisorBasicProperties,
    },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
      restriction: false,
      minRange: 1,
      maxRange: 10,
    },
    otherProperties: { ...defaultCommonProperties.supervisorOtherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  supervisor_note: {
    basicProperties: {
      ...defaultCommonProperties.supervisorBasicProperties,
    },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
    },
    otherProperties: { ...defaultCommonProperties.supervisorOtherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  supervisor_dateTime: {
    basicProperties: {
      ...defaultCommonProperties.supervisorBasicProperties,
      subType: 'date',
      showHijriDate: false,
    },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
    },
    otherProperties: { ...defaultCommonProperties.supervisorOtherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  supervisor_signature: {
    basicProperties: {
      ...defaultCommonProperties.supervisorBasicProperties,
    },
    validationProperties: {
      required: false,
      hidden: false,
      hideOnPdf: false,
    },
    otherProperties: { ...defaultCommonProperties.supervisorOtherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  supervisor_ratingSlider: {
    basicProperties: {
      ...defaultCommonProperties.supervisorBasicProperties,
      lowLabel: 'Bad',
      highLabel: 'Good',
      ratingPointers: 5,
    },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
    },
    otherProperties: { ...defaultCommonProperties.supervisorOtherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  supervisor_status: {
    basicProperties: {
      ...defaultCommonProperties.supervisorBasicProperties,
      optionList: defaultSupervisorStatusOptionsList,
    },
    validationProperties: {
      required: false,
      hidden: false,
      hideOnPdf: false,
    },
    otherProperties: { ...defaultCommonProperties.supervisorOtherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  supervisor_response: {
    basicProperties: {
      ...defaultCommonProperties.supervisorBasicProperties,
      subType: 'choiceList',
      optionList: [],
    },
    validationProperties: {
      required: false,
      readOnly: false,
      hidden: false,
      hideOnPdf: false,
    },
    otherProperties: { ...defaultCommonProperties.supervisorOtherProperties },
    mediaProperties: { ...defaultCommonProperties.mediaProperties },
  },
  supervisor_section: {
    basicProperties: { ...defaultCommonProperties.sectionBasicProperties },
    validationProperties: { hidden: false, hideOnPdf: false },
    layoutProperties: { ...defaultCommonProperties.layoutProperties, backgroundColor: '#38cb89ff' },
    otherProperties: { ...defaultCommonProperties.supervisorOtherProperties },
  },
};

export const defaultMediaProperties = { ...defaultCommonProperties.mediaProperties };
