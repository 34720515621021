import React from 'react';
import PropTypes from 'prop-types';
import plusIcon from 'assets/images/icons/plus.svg';
import searchIcon from 'assets/images/icons/search.svg';
import Filter from 'components/common-components/Filter';
import FormButton from 'components/form-components/FormButton';
import TextField from 'components/form-components/TextField';
import SelectField from 'components/form-components/SelectField';
import SortingIcon from 'assets/images/icons/sorting-icon.svg';
import { PendingUserSortOrder, UserUnassignedFilterList } from './utils';
import UserActions from './Actions';
import { permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';
import Export from 'components/common-components/Export';

const UserListHeader = ({
  filterEnabled,
  setFilterEnabled,
  actionFunction,
  selectedTab,
  sorting,
  filterByUserGroups,
  filteringData,
  setFilteringData,
  selectedRows,
  getAllUsers,
  debounceFunc,
  actionAllowedForSelectedRows,
  rowActionDataObj,
}) => {
  return (
    <div className='section-header d-flex justify-content-between px-2'>
      <div className=' d-flex  align-items-center gap-3  '>
        <TextField
          value={filteringData['search'] ? filteringData['search'] : ''}
          name='search'
          handleChange={setFilteringData}
          placeholder='field_search'
          icon={searchIcon}
          shrink
          debounceFunc={debounceFunc}
        />
        <Filter filterEnabled={filterEnabled} setFilterEnabled={setFilterEnabled} />
        <UserActions
          ids={selectedRows}
          isActionButton={false}
          type={selectedTab}
          actionsCallBack={getAllUsers}
          actionData={{ actionAllowed: actionAllowedForSelectedRows, ...rowActionDataObj }}
        />
      </div>
      <div className={`d-flex align-items-center gap-3`}>
        {selectedTab === 'user' && (
          <SelectField
            label={
              UserUnassignedFilterList.filter((item) => item.value === filterByUserGroups)[0]
                .displayValue
            }
            variant={
              UserUnassignedFilterList.filter((item) => item.value === filterByUserGroups)[0]
                .variant
            }
            handleChange={debounceFunc}
            listValues={UserUnassignedFilterList}
            name='filterByUserGroups'
            icon={SortingIcon}
            positionReversed
            isAutoClose={true}
            classes={'sorting m-0 rounded-3'}
          />
        )}
        {(selectedTab === 'user' || selectedTab === 'archived') &&
          isPermissionAvailable(permissionKeys.employees_users_read) && (
            <Export type={'user'} screenTab={selectedTab} />
          )}
        {selectedTab === 'user' && isPermissionAvailable(permissionKeys.employees_users_create) ? (
          <FormButton
            text={selectedTab === 'user' ? 'button_add_user' : ''}
            onClick={actionFunction ? actionFunction : null}
            variant='green-2'
            icon={plusIcon}
          />
        ) : selectedTab === 'pending' ? (
          <SelectField
            label={PendingUserSortOrder.filter((item) => item.value === sorting)[0].displayValue}
            variant={PendingUserSortOrder.filter((item) => item.value === sorting)[0].variant}
            handleChange={actionFunction}
            listValues={PendingUserSortOrder}
            icon={SortingIcon}
            isAutoClose={true}
            positionReversed
            classes={'sorting m-0 rounded-3'}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
UserListHeader.propTypes = {
  actionAllowedForSelectedRows: PropTypes.bool.isRequired,
  filterEnabled: PropTypes.bool.isRequired,
  setFilterEnabled: PropTypes.func.isRequired,
  actionFunction: PropTypes.func,
  selectedTab: PropTypes.string,
  sorting: PropTypes.string,
  filterByUserGroups: PropTypes.string,
  filteringData: PropTypes.object,
  setFilteringData: PropTypes.func,
  getAllUsers: PropTypes.func,
  debounceFunc: PropTypes.func,
  selectedRows: PropTypes.array.isRequired,
  rowActionDataObj: PropTypes.object.isRequired,
};
export default UserListHeader;
