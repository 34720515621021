import React from 'react';
import PropTypes from 'prop-types';
import { supervisorFieldList, userFieldList } from './utils';
import FieldList from './FieldList';

function FormFields({ selectedFieldCategory, setDraggedElement }) {
  const hiddenUserFields = ['externalApi', 'apiStatus'];
  const hiddenManagerFields = ['supervisor_section', 'supervisor_response'];

  return (
    <>
      {selectedFieldCategory === 'user-field' ? (
        <FieldList
          fieldCategory='user-field'
          fieldList={userFieldList.filter((f) => !hiddenUserFields.includes(f.elementType))}
          setDraggedElement={setDraggedElement}
          itemsDraggable
        />
      ) : (
        <FieldList
          fieldCategory='supervisor-field'
          fieldList={supervisorFieldList.filter(
            (f) => !hiddenManagerFields.includes(f.elementType),
          )}
          setDraggedElement={setDraggedElement}
          itemsDraggable
        />
      )}
    </>
  );
}

FormFields.propTypes = {
  selectedFieldCategory: PropTypes.string.isRequired,
  setSelectedField: PropTypes.func,
  setDraggedElement: PropTypes.func,
};
export default FormFields;
