import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import chevronGreyIcon from 'assets/images/icons/chevron-grey.svg';

const NestedSelect = ({
  toggleComponent,
  options,
  drop,
  align,
  name,
  handleChange,
  toggleClasses,
}) => {
  const { t } = useTranslation();

  const [nestedShow, setNestedShow] = useState(
    Array.from(Array(options.length).keys()).fill(false),
  );
  const toggleNested = (index) => {
    const tempNested = [...nestedShow].map((s, i) => (i === index ? !s : false));
    setNestedShow(tempNested);
  };

  const onRootClose = (meta) => {
    if (meta.source === 'rootClose') {
      const tempNested = [...nestedShow].fill(false);
      setNestedShow(tempNested);
    }
  };

  return (
    <div className='multi-select'>
      <Dropdown drop={drop ?? 'down'} autoClose={true} onToggle={(_, meta) => onRootClose(meta)}>
        <Dropdown.Toggle
          id='dropdown-autoclose-true'
          variant='none'
          className={`rounded-sm cursor-pointer ${toggleClasses ?? ''}`}
          as='div'
        >
          {toggleComponent}
          {/* {label && <label className='theme-size-0_9 cursor-pointer'>{t(label)}</label>}
          <img src={chevronGreyIcon} className={`rotate-180`} /> */}
        </Dropdown.Toggle>

        <Dropdown.Menu className='px-2' align={align ?? 'start'} as='div'>
          {options.map((option, i) => (
            <Fragment key={i}>
              {option.nestedOptions ? (
                <Dropdown.Item
                  as='div'
                  className='px-1 cursor-pointer'
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleNested(i);
                  }}
                  disabled={option.disabled ?? false}
                >
                  {t(option.displayValue)}
                  {option.nestedOptions.length > 0 && (
                    <NestedMenu
                      options={option.nestedOptions}
                      name={option.value}
                      handleChange={handleChange}
                      show={nestedShow[i]}
                    />
                  )}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  as='div'
                  className='px-1 cursor-pointer '
                  onClick={() => handleChange(name, option.value)}
                  disabled={option.disabled ?? false}
                >
                  {t(option.displayValue)}
                </Dropdown.Item>
              )}
            </Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

NestedSelect.propTypes = {
  toggleComponent: PropTypes.object.isRequired,
  toggleClasses: PropTypes.string,
  options: PropTypes.array.isRequired,
  drop: PropTypes.string,
  align: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const NestedMenu = ({ options, show, drop, align, name, handleChange }) => {
  const { t } = useTranslation();

  return (
    <Dropdown drop={drop ?? 'down'} show>
      <Dropdown.Toggle id='dropdown-autoclose-false' variant='none' className='p-0' as='div'>
        <img src={chevronGreyIcon} className='rotate-90 no-effect' />
      </Dropdown.Toggle>

      {show && (
        <Dropdown.Menu className='px-2 nested-menu' align={align ?? 'start'} as='div'>
          {options.map((option, i) => (
            <Dropdown.Item
              key={i}
              as='div'
              className='px-1 cursor-pointer'
              onClick={() => handleChange(name, option.value)}
              disabled={option.disabled ?? false}
            >
              {t(option.displayValue)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

NestedMenu.propTypes = {
  options: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  drop: PropTypes.string,
  align: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default NestedSelect;
