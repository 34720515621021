import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QRCodeCanvas } from 'qrcode.react';
import { difference } from 'lodash';
import { toast } from 'react-hot-toast';
import backArrow from 'assets/images/icons/arrow-left.svg';
import copyIcon from 'assets/images/icons/copy.svg';
import linkGreenIcon from 'assets/images/icons/link-green.svg';
import AuthorizeError from 'pages/AuthorizeError';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import SelectionComponent from 'components/common-components/SelectionComponent';
import TextField from 'components/form-components/TextField';
import ToggleSwitch from 'components/form-components/ToggleSwitch';
import { CallAPI } from 'actions/General';
import { permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';
import { addGroupSchema } from './utils';

const AddGroup = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [apiError, setApiError] = useState('');
  const [selectedUsers, setSelectedUsers] = useState(location?.state?.selectedDataList ?? []);
  const [hasPermissionToAccess] = useState(
    isPermissionAvailable(permissionKeys.employees_groups_create),
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState('');
  const [joinLink, setJoinLink] = useState('');
  const [values, setValues] = useState({
    groupName: '',
    users: [],
    groupSegmentId: '',
    groupId: '',
    allowJoinByLink: false,
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, [field]: value });
  };

  const submit = async () => {
    setLoading(true);
    setLoadingType('save');
    setTouched({});
    try {
      await addGroupSchema.validate(
        { ...values, users: selectedUsers.map((item) => item.id) },
        { abortEarly: false },
      );
      let APICallName = 'ADD_GROUP';
      let groupId = null;
      let userList = [];
      selectedUsers.map((item) => userList.push({ id: item.id, title: item.name }));
      let APIData = {
        groupSegmentId: values.groupSegmentId,
        groupName: values.groupName,
        users: userList,
        allowJoinByLink: values.allowJoinByLink,
      };
      if (location.state.type === 'update') {
        let userList = [];
        selectedUsers.map((item) => userList.push({ id: item.id, title: item.name }));
        let previosuUserList = [];
        location.state.selectedDataList.map((item) =>
          previosuUserList.push({ id: item.id, title: item.name }),
        );
        APICallName = 'UPDATE_GROUP';
        APIData = {
          groupName: values.groupName,
          allUsers: userList,
          addNewUsers: difference(userList, previosuUserList),
          deleteUsers: difference(previosuUserList, userList),
          allowJoinByLink: values.allowJoinByLink,
        };
        groupId = values.groupId;
      }
      let result = await CallAPI(APICallName, APIData, groupId, setLoading, setApiError, null);
      if (result.status) {
        navigate('/groups');
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
    setLoading(false);
  };

  const deleteGroups = async () => {
    setLoadingType('delete');
    await CallAPI(
      'DELETE_GROUP',
      {
        groupSegmentId: values.groupSegmentId,
        groups: [{ id: values.groupId, title: values.groupName }],
      },
      null,
      setLoading,
      null,
      null,
    );
    navigate('/groups');
  };

  const close = async () => {
    navigate(-1);
  };

  const shortenLink = (link) => {
    if (link.length <= 45) return link;
    return `${link.slice(0, 45)}...`;
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(joinLink);
    toast(t('Copied link to clipboard'), {
      style: { borderRadius: '8px', background: '#000', color: '#fff' },
    });
  };

  useEffect(() => {
    if (location.state.type === 'add')
      handleChange(
        'groupSegmentId',
        location && location.state && location.state.segmentId ? location.state.segmentId : '',
      );
    else {
      let touchedState = { ...touched };
      touchedState = { ...touchedState, groupName: true };
      touchedState = { ...touchedState, groupId: true };
      setTouched(touchedState);
      let errorsState = { ...errors };
      errorsState = { ...errorsState, groupName: undefined };
      errorsState = { ...errorsState, groupId: undefined };
      setErrors(errorsState);

      setValues({
        ...values,
        groupName: location?.state?.groupName ?? '',
        groupId: location?.state?.groupID ?? '',
        groupSegmentId: location?.state?.segmentId ?? '',
        allowJoinByLink: location?.state?.allowJoinByLink ?? false,
      });
      setJoinLink(
        `${process.env.REACT_APP_ADMIN_PANEL_URL}/invite/join-group/${
          location.state.groupID ?? ''
        }`,
      );
    }
  }, [location]);

  return (
    <>
      {!hasPermissionToAccess ? (
        <AuthorizeError />
      ) : (
        <div className='add-group theme-background-white-1 p-4'>
          <div className='d-flex align-items-center cursor-pointer' onClick={close}>
            <img src={backArrow} className='back-arrow me-2' alt='Loading...' />
            <label>{t('text_back')}</label>
          </div>
          {apiError ? <Error msg={apiError} /> : <></>}
          <div className='m-4 '>
            <div className='d-flex align-items-center gap-2'>
              <TextField
                name='groupName'
                placeholder='field_group_name'
                displayName='field_group_name'
                value={values.groupName}
                handleChange={handleChange}
                classes='w-100'
                shrink={false}
                error={errors.groupName}
                touched={touched.groupName}
                isHorizontal={true}
                horizontalLabelClass='theme-width-150'
              />
            </div>
            <div className='d-flex justify-content-between mt-4'>
              <div className='d-flex flex-column justify-content-around'>
                <div className='d-flex align-items-center gap-4'>
                  <label className='theme-text-black-1 theme-size-1'>
                    {t('text_allow_join_by_link')}
                  </label>
                  <ToggleSwitch
                    name='allowJoinByLink'
                    value={values.allowJoinByLink}
                    handleChange={handleChange}
                  />
                </div>
                {values.groupId && (
                  <div className='d-flex align-items-center flex-wrap gap-2'>
                    <FormButton
                      text={shortenLink(joinLink)}
                      variant='white-1'
                      icon={linkGreenIcon}
                    />
                    <FormButton
                      text='button_copy'
                      variant='white-1'
                      icon={copyIcon}
                      onClick={copyLinkToClipboard}
                    />
                  </div>
                )}
              </div>
              {values.groupId && (
                <div>
                  <QRCodeCanvas
                    id='qrCodeElement'
                    size={256}
                    style={{ height: 'auto', width: '100%', minWidth: '50px', maxWidth: '100px' }}
                    className='mx-4'
                    value={joinLink}
                  />
                </div>
              )}
            </div>
            <label className='w-100 text-center theme-text-black-1 theme-size-1_1 my-4'>
              {t('text_click_to_move')}
            </label>

            <SelectionComponent
              title='section_users'
              onCancel={close}
              showButtons={false}
              selectedDataList={selectedUsers}
              setSelectedList={setSelectedUsers}
              buttonsPosition='bottom'
              loading={loading}
              error={errors.users}
            />
          </div>
          <div className={`d-flex justify-content-end gap-3 me-5`}>
            <FormButton text='button_cancel' variant='white-1' onClick={close} />
            {location.state.type !== 'add' && (
              <FormLoadingButton
                text='button_delete'
                variant='red-1'
                onClick={() => setShowDeleteModal(!showDeleteModal)}
                loading={loading && loadingType === 'delete'}
              />
            )}
            <FormLoadingButton
              text='button_save'
              variant='green-1'
              onClick={submit}
              loading={loading && loadingType === 'save'}
            />
          </div>
        </div>
      )}
      <ConfirmationModal
        show={showDeleteModal}
        title={t('alert_group_deletion_title')}
        message={t('alert_group_deletion_message')}
        actionList={[
          { color: 'black-1', text: t('button_cancel'), onClick: () => setShowDeleteModal(false) },
          { color: 'red-1', text: t('button_delete'), loading, onClick: () => deleteGroups() },
        ]}
      />
    </>
  );
};

export default AddGroup;
