import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import fieldCollapseIcon from 'assets/images/icons/field-collapse.svg';
import FieldDetailView from './FieldDetailView';
import SwappingComponent from './SwappingComponent';
import { supervisorFieldList } from '../utils';

const fieldTypeMap = Object.fromEntries([...supervisorFieldList].map((f) => [f.elementType, f]));

const SupervisorFieldComponent = ({
  field,
  selectComponent,
  changeFormElementOrder,
  isSelected,
  elementIndex,
  checkSwapArrow,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`field-component-new${isSelected ? ' selected' : ''}`}>
      <div className='field-component-header'>
        <div className='d-flex gap-2 justify-content-between flex-grow-1'>
          <div
            className='d-flex align-items-center gap-2 flex-grow-1 cursor-pointer'
            onClick={() => selectComponent(elementIndex, field.elementType)}
          >
            <img src={fieldCollapseIcon} className={`${isSelected ? '' : 'svg-grey rotate-270'}`} />
            <label className='cursor-pointer theme-size-0_9'>
              {field.properties?.basicProperties?.fieldTitle
                ? field.properties?.basicProperties?.fieldTitle
                : t('form_untitled_field')}
            </label>
          </div>
        </div>
        <div className='d-flex gap-2'>
          <div className='element-icon'>
            <img src={fieldTypeMap[field.elementType].icon} />
          </div>
          <div className='d-flex gap-2'>
            <SwappingComponent
              elementType={field.elementType}
              type='supervisor_field'
              props={{ position: elementIndex }}
              changeFormElementOrder={changeFormElementOrder}
              isSelected={isSelected}
              {...checkSwapArrow(elementIndex, field.elementType, 'supervisor-field')}
            />
          </div>
        </div>
      </div>
      <div className='field-component-body'>
        <p>
          {field.properties?.basicProperties?.fieldHintText
            ? field.properties?.basicProperties?.fieldHintText
            : t('form_hint')}
        </p>
        <FieldDetailView element={field} dataSourcesList={[]} />
      </div>
    </div>
  );
};

SupervisorFieldComponent.propTypes = {
  field: PropTypes.object.isRequired,
  selectComponent: PropTypes.func.isRequired,
  changeFormElementOrder: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  elementIndex: PropTypes.number.isRequired,
  checkSwapArrow: PropTypes.func.isRequired,
};

export default SupervisorFieldComponent;
